import genreq1 from './icons/genreq1.svg';
import siteconstruction2 from './icons/siteconstruction2.svg';
import concrete3 from './icons/concrete3.svg';
import masonry4 from './icons/masonry4.svg';
import metals5 from './icons/metals5.svg';
import woodplastic6 from './icons/woodplastic6.svg';
import thermalmoisture7 from './icons/thermalmoisture7.svg';
import doorswindows8 from './icons/doorswindows8.svg';
import finishes9 from './icons/finishes9.svg';
import specialties10 from './icons/specialties10.svg';
import equipment11 from './icons/equipment11.svg';
import furnishings12 from './icons/furnishings12.svg';
import specialconstruction13 from './icons/specialconstruction13.svg';
import conveyingsystem14 from './icons/conveyingsystem14.svg';
import mechanical15 from './icons/mechanical15.svg';
import electrical16 from './icons/electrical16.svg';
import misc20 from './icons/misc20.svg';
import design21 from './icons/design21.svg';
import projsupervision22 from './icons/projsupervision22.svg';
import acousticalceiling from './icons/acousticalceiling.svg';
import asphalt from './icons/asphalt.svg';
import demo from './icons/demo.svg';
import drywall from './icons/drywall.svg';
import earthwork from './icons/earthwork.svg';
import finalcleaning from './icons/finalcleaning.svg';
import frp from './icons/frp.svg';
import glazing from './icons/glazing.svg';
import gradestaking from './icons/gradestaking.svg';
import hvac from './icons/hvac.svg';
import overheaddoors from './icons/overheaddoors.svg';
import painting from './icons/painting.svg';
import roofing from './icons/roofing.svg';
import sealer from './icons/sealer.svg';
import sheetmetal from './icons/sheetmetal.svg';

const lineItemsData = [
    { "code": "1001", "icon": genreq1, "name": "General Contractor", "checked": false, "available": true },
    { "code": "1002", "icon": genreq1, "name": "General Contractor - Airports", "checked": false, "available": true, "available": true },
    { "code": "1003", "icon": genreq1, "name": "General Contractor - Churches", "checked": false, "available": true, "available": true },
    { "code": "1004", "icon": genreq1, "name": "General Contractor - Commercial", "checked": false, "available": true, "available": true },
    { "code": "1005", "icon": genreq1, "name": "General Contractor - Project General Requirements", "checked": false, "available": true, "available": true },
    { "code": "1006", "icon": genreq1, "name": "General Contractor - Heavy/Highway", "checked": false, "available": true, "available": true },
    { "code": "1007", "icon": genreq1, "name": "General Contractor - Industrial Maintenance", "checked": false, "available": true, "available": true },
    { "code": "1008", "icon": genreq1, "name": "General Contractor - Industrial", "checked": false, "available": true, "available": true },
    { "code": "1009", "icon": genreq1, "name": "General Contractor - Institutional", "checked": false, "available": true, "available": true },
    { "code": "1010", "icon": genreq1, "name": "General Contractor - Pre Engineered Buildings", "checked": false, "available": true, "available": true },
    { "code": "1011", "icon": genreq1, "name": "General Contractor - High Rise Office", "checked": false, "available": true, "available": true },
    { "code": "1012", "icon": genreq1, "name": "General Contractor - Low Rise Office", "checked": false, "available": true, "available": true },
    { "code": "1013", "icon": genreq1, "name": "General Contractor - High Rise Apartments", "checked": false, "available": true, "available": true },
    { "code": "1014", "icon": genreq1, "name": "General Contractor - Low Rise Apartments", "checked": false, "available": true, "available": true },
    { "code": "1015", "icon": genreq1, "name": "General Contractor - Waste Water Treatment", "checked": false, "available": true, "available": true },
    { "code": "1016", "icon": genreq1, "name": "General Contractor - Construction Manager", "checked": false, "available": true, "available": true },
    { "code": "1017", "icon": genreq1, "name": "General Contractor - Facilities Manager", "checked": false, "available": true, "available": true },
    { "code": "1018", "icon": genreq1, "name": "General Contractor - Historical Renovation", "checked": false, "available": true, "available": true },
    { "code": "1019", "icon": genreq1, "name": "General Contractor - Tenant Finish", "checked": false, "available": true, "available": true },
    { "code": "1021", "icon": genreq1, "name": "General Contractor - Interior Finish", "checked": false, "available": true, "available": true },
    { "code": "1024", "icon": genreq1, "name": "General Contractor - Farm Buildings", "checked": false, "available": true, "available": true },
    { "code": "1025", "icon": genreq1, "name": "General Contractor - Health Care Facilities/Services", "checked": false, "available": true, "available": true },
    { "code": "1026", "icon": genreq1, "name": "General Contractor - Hangar & Maintenance", "checked": false, "available": true, "available": true },
    { "code": "1030", "icon": genreq1, "name": "General Contractor - Industrial & Commercial", "checked": false, "available": true, "available": true },
    { "code": "1031", "icon": genreq1, "name": "General Contractor - Custom Built Homes", "checked": false, "available": true, "available": true },
    { "code": "1032", "icon": genreq1, "name": "General Contractor - Residential Multi Family", "checked": false, "available": true, "available": true },
    { "code": "1033", "icon": genreq1, "name": "General Contractor - Retail", "checked": false, "available": true, "available": true },
    { "code": "1040", "icon": genreq1, "name": "General Contractor - Other Specialty Construction", "checked": false, "available": true, "available": true },
    { "code": "1060", "icon": genreq1, "name": "Construction Management/Coordination Firm", "checked": false, "available": true, "available": true },
    { "code": "1310", "icon": genreq1, "name": "Project Scheduling", "checked": false, "available": true, "available": true },
    { "code": "1330", "icon": genreq1, "name": "Engineering Services", "checked": false, "available": true, "available": true },
    { "code": "1380", "icon": genreq1, "name": "Construction Documentation", "checked": false, "available": true, "available": true },
    { "code": "1400", "icon": genreq1, "name": "Quality Control Services", "checked": false, "available": true, "available": true },
    { "code": "1500", "icon": genreq1, "name": "Temporary Facilities", "checked": false, "available": true, "available": true },
    { "code": "1505", "icon": genreq1, "name": "Portable Toilets", "checked": false, "available": true, "available": true },
    { "code": "1510", "icon": genreq1, "name": "Temporary Utilities", "checked": false, "available": true, "available": true },
    { "code": "1520", "icon": genreq1, "name": "Security Services", "checked": false, "available": true },
    { "code": "1525", "icon": genreq1, "name": "Construction Aids", "checked": false, "available": true },
    { "code": "1560", "icon": genreq1, "name": "Temporary Controls", "checked": false, "available": true },
    { "code": "1580", "icon": genreq1, "name": "Project Identification and Signs", "checked": false, "available": true },
    { "code": "1600", "icon": genreq1, "name": "Construction Equipment", "checked": false, "available": true },
    { "code": "1630", "icon": genreq1, "name": "Construction Tools", "checked": false, "available": true },
    { "code": "1710", "icon": finalcleaning, "name": "Construction Cleaning Services", "checked": false, "available": true },
    { "code": "1905", "icon": genreq1, "name": "Insurance and Bonding", "checked": false, "available": true },
    { "code": "2010", "icon": siteconstruction2, "name": "Site Work Supplier", "checked": false, "available": true },
    { "code": "2015", "icon": gradestaking, "name": "Grade Staking", "checked": false, "available": true },
    { "code": "2040", "icon": siteconstruction2, "name": "Aggregate Manufacture Supplier", "checked": false, "available": true },
    { "code": "2050", "icon": demo, "name": "Demolition", "checked": false, "available": true },
    { "code": "2055", "icon": siteconstruction2, "name": "Hazardous Material Removal", "checked": false, "available": true },
    { "code": "2060", "icon": siteconstruction2, "name": "Off-site Transportation and Disposal", "checked": false, "available": true },
    { "code": "2065", "icon": siteconstruction2, "name": "Cement", "checked": false, "available": true },
    { "code": "2100", "icon": siteconstruction2, "name": "Site Preparation", "checked": false, "available": true },
    { "code": "2105", "icon": siteconstruction2, "name": "Earthwork Supplies", "checked": false, "available": true },
    { "code": "2107", "icon": siteconstruction2, "name": "Geosynthetics", "checked": false, "available": true },
    { "code": "2140", "icon": siteconstruction2, "name": "Dewatering", "checked": false, "available": true },
    { "code": "2150", "icon": siteconstruction2, "name": "Excavation Support & Shoring Systems", "checked": false, "available": true },
    { "code": "2200", "icon": earthwork, "name": "Earthwork", "checked": false, "available": true },
    { "code": "2240", "icon": siteconstruction2, "name": "Soil Stabilization", "checked": false, "available": true },
    { "code": "2280", "icon": siteconstruction2, "name": "Soil Treatment", "checked": false, "available": true },
    { "code": "2300", "icon": siteconstruction2, "name": "Tunneling", "checked": false, "available": true },
    { "code": "2315", "icon": siteconstruction2, "name": "Man Hole Covers", "checked": false, "available": true },
    { "code": "2350", "icon": siteconstruction2, "name": "Piling", "checked": false, "available": true },
    { "code": "2380", "icon": siteconstruction2, "name": "Caissons/Drilled Piers", "checked": false, "available": true },
    { "code": "2400", "icon": siteconstruction2, "name": "Underground Utilities", "checked": false, "available": true },
    { "code": "2450", "icon": siteconstruction2, "name": "Railroad Work", "checked": false, "available": true },
    { "code": "2480", "icon": siteconstruction2, "name": "Marine Work", "checked": false, "available": true },
    { "code": "2485", "icon": siteconstruction2, "name": "Marine Diving", "checked": false, "available": true },
    { "code": "2510", "icon": asphalt, "name": "Asphalt Paving", "checked": false, "available": true },
    { "code": "2513", "icon": siteconstruction2, "name": "Asphalt Paving Concrete Materials", "checked": false, "available": true },
    { "code": "2515", "icon": siteconstruction2, "name": "Unit Pavers", "checked": false, "available": true },
    { "code": "2516", "icon": siteconstruction2, "name": "Base Courses", "checked": false, "available": true },
    { "code": "2520", "icon": siteconstruction2, "name": "Concrete Paving", "checked": false, "available": true },
    { "code": "2525", "icon": siteconstruction2, "name": "Curbs", "checked": false, "available": true },
    { "code": "2530", "icon": siteconstruction2, "name": "Athletic Paving & Surfacing", "checked": false, "available": true },
    { "code": "2570", "icon": siteconstruction2, "name": "Site Concrete", "checked": false, "available": true },
    { "code": "2580", "icon": siteconstruction2, "name": "Pavement Marking", "checked": false, "available": true },
    { "code": "2600", "icon": siteconstruction2, "name": "Site Utilities Pressure Piping", "checked": false, "available": true },
    { "code": "2670", "icon": siteconstruction2, "name": "Water Wells", "checked": false, "available": true },
    { "code": "2700", "icon": siteconstruction2, "name": "Sewerage and Drainage", "checked": false, "available": true },
    { "code": "2710", "icon": siteconstruction2, "name": "Septic Systems", "checked": false, "available": true },
    { "code": "2760", "icon": siteconstruction2, "name": "Restoration of Underground Pipelines", "checked": false, "available": true },
    { "code": "2770", "icon": siteconstruction2, "name": "Ponds and Reservoirs", "checked": false, "available": true },
    { "code": "2800", "icon": siteconstruction2, "name": "Site Improvements", "checked": false, "available": true },
    { "code": "2830", "icon": siteconstruction2, "name": "Fences & Gates", "checked": false, "available": true },
    { "code": "2900", "icon": siteconstruction2, "name": "Landscaping and Irrigation", "checked": false, "available": true },
    { "code": "3010", "icon": concrete3, "name": "Concrete Contractor", "checked": false, "available": true },
    { "code": "3050", "icon": concrete3, "name": "Concrete Supplier", "checked": false, "available": true },
    { "code": "3100", "icon": concrete3, "name": "Concrete Formwork", "checked": false, "available": true },
    { "code": "3200", "icon": concrete3, "name": "Concrete Reinforcing", "checked": false, "available": true },
    { "code": "3210", "icon": concrete3, "name": "Reinforcing Steel", "checked": false, "available": true },
    { "code": "3300", "icon": concrete3, "name": "Cast In Place Concrete", "checked": false, "available": true },
    { "code": "3310", "icon": concrete3, "name": "Cement Supplier", "checked": false, "available": true },
    { "code": "3320", "icon": concrete3, "name": "Ready-Mix Concrete", "checked": false, "available": true },
    { "code": "3345", "icon": concrete3, "name": "Concrete Placing and Finishing", "checked": false, "available": true },
    { "code": "3346", "icon": concrete3, "name": "Concrete Curling Supplier", "checked": false, "available": true },
    { "code": "3347", "icon": concrete3, "name": "Grout", "checked": false, "available": true },
    { "code": "3350", "icon": concrete3, "name": "Special Finish Concrete", "checked": false, "available": true },
    { "code": "3360", "icon": concrete3, "name": "Specially Placed Concrete", "checked": false, "available": true },
    { "code": "3400", "icon": concrete3, "name": "Precast Concrete", "checked": false, "available": true },
    { "code": "3460", "icon": concrete3, "name": "Site Cast Precast Concrete", "checked": false, "available": true },
    { "code": "3480", "icon": concrete3, "name": "Precast Concrete Specialties", "checked": false, "available": true },
    { "code": "3500", "icon": concrete3, "name": "Cementitious Decks", "checked": false, "available": true },
    { "code": "3510", "icon": concrete3, "name": "Gypsum Concrete", "checked": false, "available": true },
    { "code": "3540", "icon": concrete3, "name": "Concrete Sealer", "checked": false, "available": true },
    { "code": "3700", "icon": concrete3, "name": "Concrete Restoration and Cleaning", "checked": false, "available": true },
    { "code": "4200", "icon": masonry4, "name": "Masonry", "checked": false, "available": true },
    { "code": "4331", "icon": masonry4, "name": "Brick Masonry Supplies", "checked": false, "available": true },
    { "code": "4332", "icon": masonry4, "name": "Mortar & Masonry Grout", "checked": false, "available": true },
    { "code": "4333", "icon": masonry4, "name": "Reinforced Unit Masonry", "checked": false, "available": true },
    { "code": "4336", "icon": masonry4, "name": "Washed Sand & Gravel", "checked": false, "available": true },
    { "code": "4400", "icon": masonry4, "name": "Stone", "checked": false, "available": true },
    { "code": "4500", "icon": masonry4, "name": "Masonry Restoration and Cleaning", "checked": false, "available": true },
    { "code": "4550", "icon": masonry4, "name": "Special Masonry Installations", "checked": false, "available": true },
    { "code": "5010", "icon": metals5, "name": "Structural Metal Erection", "checked": false, "available": true },
    { "code": "5050", "icon": metals5, "name": "Basic Metal Materials and Methods", "checked": false, "available": true },
    { "code": "5100", "icon": metals5, "name": "Structural Framing", "checked": false, "available": true },
    { "code": "5105", "icon": metals5, "name": "Millwright", "checked": false, "available": true },
    { "code": "5110", "icon": metals5, "name": "Rigging", "checked": false, "available": true },
    { "code": "5120", "icon": metals5, "name": "Structural Steel", "checked": false, "available": true },
    { "code": "5210", "icon": metals5, "name": "Steel Joists", "checked": false, "available": true },
    { "code": "5300", "icon": metals5, "name": "Metal Decking", "checked": false, "available": true },
    { "code": "5400", "icon": metals5, "name": "Cold Formed Metal Framing", "checked": false, "available": true },
    { "code": "5410", "icon": metals5, "name": "Load Bearing Metal Studs", "checked": false, "available": true },
    { "code": "5500", "icon": metals5, "name": "Miscellaneous Metal Fabrications", "checked": false, "available": true },
    { "code": "5520", "icon": metals5, "name": "Metal Handrails & Railings", "checked": false, "available": true },
    { "code": "5535", "icon": metals5, "name": "Gratings and Floor Plates", "checked": false, "available": true },
    { "code": "5560", "icon": metals5, "name": "Metal Castings", "checked": false, "available": true },
    { "code": "5700", "icon": metals5, "name": "Ornamental Metal", "checked": false, "available": true },
    { "code": "5800", "icon": metals5, "name": "Expansion Control", "checked": false, "available": true },
    { "code": "5900", "icon": metals5, "name": "Metal Restoration and Cleaning", "checked": false, "available": true },
    { "code": "6090", "icon": woodplastic6, "name": "Wood And Plastic Fastenings", "checked": false, "available": true },
    { "code": "6100", "icon": woodplastic6, "name": "Rough Carpentry", "checked": false, "available": true },
    { "code": "6130", "icon": woodplastic6, "name": "Structural Wood Components", "checked": false, "available": true },
    { "code": "6170", "icon": woodplastic6, "name": "Roof Structure", "checked": false, "available": true },
    { "code": "6200", "icon": woodplastic6, "name": "Finish Carpentry/Architectural Woodwork", "checked": false, "available": true },
    { "code": "6220", "icon": woodplastic6, "name": "Millwork", "checked": false, "available": true },
    { "code": "6300", "icon": woodplastic6, "name": "Lumber", "checked": false, "available": true },
    { "code": "6415", "icon": woodplastic6, "name": "Countertops", "checked": false, "available": true },
    { "code": "6600", "icon": woodplastic6, "name": "Plastic Fabrications", "checked": false, "available": true },
    { "code": "6900", "icon": woodplastic6, "name": "Wood and Plastic Restoration and Cleaning", "checked": false, "available": true },
    { "code": "7100", "icon": thermalmoisture7, "name": "Waterproofing/Dampproofing", "checked": false, "available": true },
    { "code": "7200", "icon": thermalmoisture7, "name": "Insulation", "checked": false, "available": true },
    { "code": "7240", "icon": thermalmoisture7, "name": "Exterior Insulation and Finish Systems", "checked": false, "available": true },
    { "code": "7250", "icon": thermalmoisture7, "name": "Fireproofing", "checked": false, "available": true },
    { "code": "7310", "icon": thermalmoisture7, "name": "Shingles", "checked": false, "available": true },
    { "code": "7320", "icon": thermalmoisture7, "name": "Roofing Tiles", "checked": false, "available": true },
    { "code": "7400", "icon": thermalmoisture7, "name": "Preformed Roofing and Cladding/Siding", "checked": false, "available": true },
    { "code": "7405", "icon": thermalmoisture7, "name": "Roofing Supplier", "checked": false, "available": true },
    { "code": "7500", "icon": thermalmoisture7, "name": "Membrane Roofing and Sheet Metal", "checked": false, "available": true },
    { "code": "7510", "icon": roofing, "name": "Built-Up Roofing", "checked": false, "available": true },
    { "code": "7620", "icon": sheetmetal, "name": "Sheet Metal Flashing", "checked": false, "available": true },
    { "code": "7700", "icon": thermalmoisture7, "name": "Roof Specialties", "checked": false, "available": true },
    { "code": "7800", "icon": thermalmoisture7, "name": "Skylights", "checked": false, "available": true },
    { "code": "7840", "icon": thermalmoisture7, "name": "Firestopping", "checked": false, "available": true },
    { "code": "7900", "icon": sealer, "name": "Joint Sealers", "checked": false, "available": true },
    { "code": "8100", "icon": doorswindows8, "name": "Metal Doors and Frames", "checked": false, "available": true },
    { "code": "8110", "icon": doorswindows8, "name": "Man Doors, Frames and Hardware", "checked": false, "available": true },
    { "code": "8120", "icon": doorswindows8, "name": "Special Metal Doors and Frames", "checked": false, "available": true },
    { "code": "8200", "icon": doorswindows8, "name": "Wood and Plastic Doors", "checked": false, "available": true },
    { "code": "8300", "icon": doorswindows8, "name": "Special Doors", "checked": false, "available": true },
    { "code": "8305", "icon": doorswindows8, "name": "Access Doors", "checked": false, "available": true },
    { "code": "8310", "icon": doorswindows8, "name": "Sliding Doors", "checked": false, "available": true },
    { "code": "8330", "icon": overheaddoors, "name": "Overhead and Coiling Doors", "checked": false, "available": true },
    { "code": "8350", "icon": doorswindows8, "name": "Folding Doors and Grilles", "checked": false, "available": true },
    { "code": "8400", "icon": glazing, "name": "Entrances, Storefronts, Glazing and Curtain Wall", "checked": false, "available": true },
    { "code": "8500", "icon": doorswindows8, "name": "Metal Windows", "checked": false, "available": true },
    { "code": "8600", "icon": doorswindows8, "name": "Wood and Plastic Windows", "checked": false, "available": true },
    { "code": "8650", "icon": doorswindows8, "name": "Special Windows", "checked": false, "available": true },
    { "code": "8700", "icon": doorswindows8, "name": "Hardware", "checked": false, "available": true },
    { "code": "9050", "icon": finishes9, "name": "Basic Finish Materials And Methods", "checked": false, "available": true },
    { "code": "9120", "icon": finishes9, "name": "Ceiling Suspension", "checked": false, "available": true },
    { "code": "9200", "icon": finishes9, "name": "Lath and Plaster", "checked": false, "available": true },
    { "code": "9250", "icon": drywall, "name": "Drywall", "checked": false, "available": true },
    { "code": "9300", "icon": finishes9, "name": "Tile and Terrazzo", "checked": false, "available": true },
    { "code": "9500", "icon": finishes9, "name": "Acoustical Treatment", "checked": false, "available": true },
    { "code": "9510", "icon": acousticalceiling, "name": "Acoustical Ceiling", "checked": false, "available": true },
    { "code": "9540", "icon": frp, "name": "FRP", "checked": false, "available": true },
    { "code": "9550", "icon": finishes9, "name": "Wood Flooring", "checked": false, "available": true },
    { "code": "9600", "icon": finishes9, "name": "Stone & Masonry Flooring", "checked": false, "available": true },
    { "code": "9610", "icon": finishes9, "name": "Cultured Marble", "checked": false, "available": true },
    { "code": "9650", "icon": finishes9, "name": "Soft Flooring", "checked": false, "available": true },
    { "code": "9680", "icon": finishes9, "name": "Carpet", "checked": false, "available": true },
    { "code": "9700", "icon": finishes9, "name": "Special Flooring and Floor Treatment", "checked": false, "available": true },
    { "code": "9790", "icon": finishes9, "name": "Paint Removal", "checked": false, "available": true },
    { "code": "9800", "icon": finishes9, "name": "Special Coatings", "checked": false, "available": true },
    { "code": "9900", "icon": painting, "name": "Painting and Wall Coverings", "checked": false, "available": true },
    { "code": "9950", "icon": finishes9, "name": "Wall coverings", "checked": false, "available": true },
    { "code": "9980", "icon": finishes9, "name": "Sandblasting", "checked": false, "available": true },
    { "code": "10100", "icon": specialties10, "name": "Chalkboards and Tackboards", "checked": false, "available": true },
    { "code": "10150", "icon": specialties10, "name": "Compartments and Cubicles", "checked": false, "available": true },
    { "code": "10200", "icon": specialties10, "name": "Louvers, Vents and Screens", "checked": false, "available": true },
    { "code": "10260", "icon": specialties10, "name": "Wall and Corner Guards", "checked": false, "available": true },
    { "code": "10270", "icon": specialties10, "name": "Access Flooring", "checked": false, "available": true },
    { "code": "10280", "icon": specialties10, "name": "Specialty Modules", "checked": false, "available": true },
    { "code": "10300", "icon": specialties10, "name": "Fireplaces and Stoves", "checked": false, "available": true },
    { "code": "10340", "icon": specialties10, "name": "Prefabricated Exterior Specialties", "checked": false, "available": true },
    { "code": "10350", "icon": specialties10, "name": "Flagpoles", "checked": false, "available": true },
    { "code": "10400", "icon": specialties10, "name": "Identifying Devices", "checked": false, "available": true },
    { "code": "10450", "icon": specialties10, "name": "Pedestrian Control Devices", "checked": false, "available": true },
    { "code": "10500", "icon": specialties10, "name": "Lockers", "checked": false, "available": true },
    { "code": "10520", "icon": specialties10, "name": "Fire Protection Specialties", "checked": false, "available": true },
    { "code": "10530", "icon": specialties10, "name": "Protective Covers", "checked": false, "available": true },
    { "code": "10550", "icon": specialties10, "name": "Postal Specialties", "checked": false, "available": true },
    { "code": "10600", "icon": specialties10, "name": "Partitions", "checked": false, "available": true },
    { "code": "10650", "icon": specialties10, "name": "Operable Partitions", "checked": false, "available": true },
    { "code": "10670", "icon": specialties10, "name": "Storage Shelving", "checked": false, "available": true },
    { "code": "10700", "icon": specialties10, "name": "Exterior Sun Control Devices", "checked": false, "available": true },
    { "code": "10750", "icon": specialties10, "name": "Telephone Specialties", "checked": false, "available": true },
    { "code": "10800", "icon": specialties10, "name": "Toilet and Bath Accessories", "checked": false, "available": true },
    { "code": "10880", "icon": specialties10, "name": "Scales", "checked": false, "available": true },
    { "code": "10900", "icon": specialties10, "name": "Wardrobe and Closet Specialties", "checked": false, "available": true },
    { "code": "10950", "icon": specialties10, "name": "Miscellaneous Specialties", "checked": false, "available": true },
    { "code": "11010", "icon": equipment11, "name": "Maintenance Equipment", "checked": false, "available": true },
    { "code": "11020", "icon": equipment11, "name": "Bank, Security and Vault Equipment", "checked": false, "available": true },
    { "code": "11040", "icon": equipment11, "name": "Ecclesiastical Equipment", "checked": false, "available": true },
    { "code": "11050", "icon": equipment11, "name": "Library Equipment", "checked": false, "available": true },
    { "code": "11060", "icon": equipment11, "name": "Theater and Stage Equipment", "checked": false, "available": true },
    { "code": "11070", "icon": equipment11, "name": "Instrumental Equipment", "checked": false, "available": true },
    { "code": "11100", "icon": equipment11, "name": "Mercantile Equipment", "checked": false, "available": true },
    { "code": "11110", "icon": equipment11, "name": "Commercial Laundry and Dry Cleaning Equipment", "checked": false, "available": true },
    { "code": "11120", "icon": equipment11, "name": "Vending Equipment", "checked": false, "available": true },
    { "code": "11130", "icon": equipment11, "name": "Audio Visual Equipment", "checked": false, "available": true },
    { "code": "11140", "icon": equipment11, "name": "Service Station Equipment", "checked": false, "available": true },
    { "code": "11145", "icon": equipment11, "name": "Air Compressor", "checked": false, "available": true },
    { "code": "11150", "icon": equipment11, "name": "Parking Control Equipment", "checked": false, "available": true },
    { "code": "11160", "icon": equipment11, "name": "Loading Dock Equipment", "checked": false, "available": true },
    { "code": "11170", "icon": equipment11, "name": "Solid Waste Handling Equipment", "checked": false, "available": true },
    { "code": "11190", "icon": equipment11, "name": "Detention Equipment", "checked": false, "available": true },
    { "code": "11200", "icon": equipment11, "name": "Water Supply and Treatment Equipment", "checked": false, "available": true },
    { "code": "11280", "icon": equipment11, "name": "Dam, Hydroelectric, and Irrigation Project", "checked": false, "available": true },
    { "code": "11300", "icon": equipment11, "name": "Fluid Waste Treatment and Disposal Equipment", "checked": false, "available": true },
    { "code": "11400", "icon": equipment11, "name": "Food Service Equipment", "checked": false, "available": true },
    { "code": "11450", "icon": equipment11, "name": "Residential Equipment", "checked": false, "available": true },
    { "code": "11460", "icon": equipment11, "name": "Unit Kitchens", "checked": false, "available": true },
    { "code": "11470", "icon": equipment11, "name": "Darkroom Equipment", "checked": false, "available": true },
    { "code": "11480", "icon": equipment11, "name": "Athletic, Recreational and Therapeutic Equipment", "checked": false, "available": true },
    { "code": "11500", "icon": equipment11, "name": "Industrial and Process Equipment", "checked": false, "available": true },
    { "code": "11600", "icon": equipment11, "name": "Laboratory Equipment", "checked": false, "available": true },
    { "code": "11660", "icon": equipment11, "name": "Planetarium and Observatory Equipment", "checked": false, "available": true },
    { "code": "11700", "icon": equipment11, "name": "Medical Equipment", "checked": false, "available": true },
    { "code": "11780", "icon": equipment11, "name": "Mortuary Equipment", "checked": false, "available": true },
    { "code": "11850", "icon": equipment11, "name": "Navigation Equipment", "checked": false, "available": true },
    { "code": "11900", "icon": equipment11, "name": "Miscellaneous Specialty Equipment", "checked": false, "available": true },
    { "code": "12100", "icon": furnishings12, "name": "Artwork", "checked": false, "available": true },
    { "code": "12300", "icon": furnishings12, "name": "Manufactured Casework", "checked": false, "available": true },
    { "code": "12335", "icon": furnishings12, "name": "Medical Casework", "checked": false, "available": true },
    { "code": "12390", "icon": furnishings12, "name": "Residential Casework", "checked": false, "available": true },
    { "code": "12500", "icon": furnishings12, "name": "Window Treatment", "checked": false, "available": true },
    { "code": "12610", "icon": furnishings12, "name": "Landscape Partitions and Components", "checked": false, "available": true },
    { "code": "12620", "icon": furnishings12, "name": "Furniture, Furniture Systems, and Furniture", "checked": false, "available": true },
    { "code": "12670", "icon": furnishings12, "name": "Rugs and Mats", "checked": false, "available": true },
    { "code": "12700", "icon": furnishings12, "name": "Multiple Seating", "checked": false, "available": true },
    { "code": "12750", "icon": furnishings12, "name": "Systems Furniture", "checked": false, "available": true },
    { "code": "12800", "icon": furnishings12, "name": "Interior Plants and Planters", "checked": false, "available": true },
    { "code": "13010", "icon": specialconstruction13, "name": "Air Supported Structures", "checked": false, "available": true },
    { "code": "13020", "icon": specialconstruction13, "name": "Integrated Assemblies", "checked": false, "available": true },
    { "code": "13030", "icon": specialconstruction13, "name": "Special Purpose Rooms", "checked": false, "available": true },
    { "code": "13080", "icon": specialconstruction13, "name": "Sound, Vibration, and Seismic Control", "checked": false, "available": true },
    { "code": "13090", "icon": specialconstruction13, "name": "Radiation Protection", "checked": false, "available": true },
    { "code": "13100", "icon": specialconstruction13, "name": "Nuclear Reactors", "checked": false, "available": true },
    { "code": "13120", "icon": specialconstruction13, "name": "Pre Engineered Structures", "checked": false, "available": true },
    { "code": "13150", "icon": specialconstruction13, "name": "Pools", "checked": false, "available": true },
    { "code": "13160", "icon": specialconstruction13, "name": "Ice Rinks", "checked": false, "available": true },
    { "code": "13170", "icon": specialconstruction13, "name": "Kennels and Animal Shelters", "checked": false, "available": true },
    { "code": "13180", "icon": specialconstruction13, "name": "Site Constructed Incinerators", "checked": false, "available": true },
    { "code": "13200", "icon": specialconstruction13, "name": "Liquid and Gas Storage Tanks", "checked": false, "available": true },
    { "code": "13230", "icon": specialconstruction13, "name": "Digestion Tank Covers and Appurtenances", "checked": false, "available": true },
    { "code": "13240", "icon": specialconstruction13, "name": "Oxygenation Systems", "checked": false, "available": true },
    { "code": "13260", "icon": specialconstruction13, "name": "Sludge Conditioning Systems", "checked": false, "available": true },
    { "code": "13300", "icon": specialconstruction13, "name": "Utility Control Systems", "checked": false, "available": true },
    { "code": "13400", "icon": specialconstruction13, "name": "Industrial and Process Control Systems", "checked": false, "available": true },
    { "code": "13500", "icon": specialconstruction13, "name": "Recording Instrumentation", "checked": false, "available": true },
    { "code": "13550", "icon": specialconstruction13, "name": "Transportation Control Instrumentation", "checked": false, "available": true },
    { "code": "13600", "icon": specialconstruction13, "name": "Solar Energy Systems", "checked": false, "available": true },
    { "code": "13700", "icon": specialconstruction13, "name": "Wind Energy Systems", "checked": false, "available": true },
    { "code": "13800", "icon": specialconstruction13, "name": "Building Automation Systems", "checked": false, "available": true },
    { "code": "13900", "icon": specialconstruction13, "name": "Fire Suppression and Supervisory Systems", "checked": false, "available": true },
    { "code": "14100", "icon": conveyingsystem14, "name": "Dumbwaiters", "checked": false, "available": true },
    { "code": "14200", "icon": conveyingsystem14, "name": "Elevators", "checked": false, "available": true },
    { "code": "14300", "icon": conveyingsystem14, "name": "Moving Stairs and Walks", "checked": false, "available": true },
    { "code": "14400", "icon": conveyingsystem14, "name": "Lifts", "checked": false, "available": true },
    { "code": "14450", "icon": conveyingsystem14, "name": "Vehicle Lifts", "checked": false, "available": true },
    { "code": "14500", "icon": conveyingsystem14, "name": "Material Handling Systems", "checked": false, "available": true },
    { "code": "14560", "icon": conveyingsystem14, "name": "Chutes", "checked": false, "available": true },
    { "code": "14580", "icon": conveyingsystem14, "name": "Tube Systems", "checked": false, "available": true },
    { "code": "14600", "icon": conveyingsystem14, "name": "Hoists and Cranes", "checked": false, "available": true },
    { "code": "14700", "icon": conveyingsystem14, "name": "Turntables", "checked": false, "available": true },
    { "code": "14800", "icon": conveyingsystem14, "name": "Scaffolding", "checked": false, "available": true },
    { "code": "14900", "icon": conveyingsystem14, "name": "Transportation Systems", "checked": false, "available": true },
    { "code": "15050", "icon": mechanical15, "name": "Basic Mechanical Materials And Methods", "checked": false, "available": true },
    { "code": "15230", "icon": mechanical15, "name": "Industrial Process Piping", "checked": false, "available": true },
    { "code": "15250", "icon": mechanical15, "name": "Mechanical Insulation", "checked": false, "available": true },
    { "code": "15300", "icon": mechanical15, "name": "Fire Protection", "checked": false, "available": true },
    { "code": "15400", "icon": mechanical15, "name": "Plumbing", "checked": false, "available": true },
    { "code": "15500", "icon": hvac, "name": "Heating, Ventilating, and Air Conditioning (HVAC)", "checked": false, "available": true },
    { "code": "15510", "icon": mechanical15, "name": "Heating Boilers And Accessories", "checked": false, "available": true },
    { "code": "15800", "icon": mechanical15, "name": "Process Piping & Equipment", "checked": false, "available": true },
    { "code": "15950", "icon": mechanical15, "name": "Controls", "checked": false, "available": true },
    { "code": "15990", "icon": mechanical15, "name": "Testing, Adjusting, and Balancing", "checked": false, "available": true },
    { "code": "16050", "icon": electrical16, "name": "Basic Electrical Materials And Methods", "checked": false, "available": true },
    { "code": "16100", "icon": electrical16, "name": "Electrical Contractor", "checked": false, "available": true },
    { "code": "16300", "icon": electrical16, "name": "Site Electrical Distribution", "checked": false, "available": true },
    { "code": "16320", "icon": electrical16, "name": "High Voltage Distribution, Switching and Protection", "checked": false, "available": true },
    { "code": "16360", "icon": electrical16, "name": "Fire Monitor System", "checked": false, "available": true },
    { "code": "16400", "icon": electrical16, "name": "Industrial Electrical Work", "checked": false, "available": true },
    { "code": "16670", "icon": electrical16, "name": "Lightning Protection Systems", "checked": false, "available": true },
    { "code": "16700", "icon": electrical16, "name": "Communications", "checked": false, "available": true },
    { "code": "16750", "icon": electrical16, "name": "Alarm Systems", "checked": false, "available": true },
    { "code": "16800", "icon": electrical16, "name": "Fiber Optic Cabling", "checked": false, "available": true },
    { "code": "16900", "icon": electrical16, "name": "Controls", "checked": false, "available": true },
    { "code": "16950", "icon": electrical16, "name": "Testing", "checked": false, "available": true },
    { "code": "20100", "icon": misc20, "name": "Accounting", "checked": false, "available": true },
    { "code": "20200", "icon": misc20, "name": "Consulting", "checked": false, "available": true },
    { "code": "20300", "icon": misc20, "name": "Attorney", "checked": false, "available": true },
    { "code": "20400", "icon": misc20, "name": "Developer", "checked": false, "available": true },
    { "code": "20700", "icon": misc20, "name": "Business Services", "checked": false, "available": true },
    { "code": "20800", "icon": misc20, "name": "Insurance", "checked": false, "available": true },
    { "code": "20805", "icon": misc20, "name": "Bonding", "checked": false, "available": true },
    { "code": "20810", "icon": misc20, "name": "Banking", "checked": false, "available": true },
    { "code": "20900", "icon": misc20, "name": "Supplier", "checked": false, "available": true },
    { "code": "203450", "icon": misc20, "name": "Cement Based Underlayment", "checked": false, "available": true },
    { "code": "201000", "icon": misc20, "name": "Construction Reports", "checked": false, "available": true },
    { "code": "201100", "icon": misc20, "name": "Construction Employment Firm", "checked": false, "available": true },
    { "code": "201200", "icon": misc20, "name": "Advertising", "checked": false, "available": true },
    { "code": "201300", "icon": misc20, "name": "Internet Services", "checked": false, "available": true },
    { "code": "201400", "icon": misc20, "name": "Timberline Software", "checked": false, "available": true },
    { "code": "201500", "icon": misc20, "name": "Chapter Lobbyist", "checked": false, "available": true },
    { "code": "201600", "icon": misc20, "name": "Environmental Services", "checked": false, "available": true },
    { "code": "201800", "icon": misc20, "name": "Labor Staffing Services", "checked": false, "available": true },
    { "code": "202010", "icon": misc20, "name": "Arborist", "checked": false, "available": true },
    { "code": "20202020", "icon": misc20, "name": "Dumpster Service/Rolloff/Transfer Station", "checked": false, "available": true },
    { "code": "20202030", "icon": misc20, "name": "Employee Benefits", "checked": false, "available": true },
    { "code": "20202040", "icon": misc20, "name": "Financial Planning", "checked": false, "available": true },
    { "code": "20202050", "icon": misc20, "name": "Prevailing Wage Benefit Plans", "checked": false, "available": true },
    { "code": "20202060", "icon": misc20, "name": "Printing & Reproduction", "checked": false, "available": true },
    { "code": "20202070", "icon": misc20, "name": "Online Planroom", "checked": false, "available": true },
    { "code": "20202080", "icon": misc20, "name": "Automotive Dealership", "checked": false, "available": true },
    { "code": "20202090", "icon": misc20, "name": "Safety Services", "checked": false, "available": true },
    { "code": "20202100", "icon": misc20, "name": "Signs", "checked": false, "available": true },
    { "code": "21010", "icon": design21, "name": "Processing and Coordination", "checked": false, "available": true },
    { "code": "21020", "icon": design21, "name": "Architectural Design", "checked": false, "available": true },
    { "code": "21025", "icon": design21, "name": "Civil Engineering", "checked": false, "available": true },
    { "code": "21030", "icon": design21, "name": "Structural Engineering", "checked": false, "available": true },
    { "code": "21040", "icon": design21, "name": "Mechanical Engineering", "checked": false, "available": true },
    { "code": "21045", "icon": design21, "name": "Electrical Engineering", "checked": false, "available": true },
    { "code": "21050", "icon": design21, "name": "Landscape Architecture", "checked": false, "available": true },
    { "code": "21055", "icon": design21, "name": "Tests and Inspections", "checked": false, "available": true },
    { "code": "21060", "icon": design21, "name": "Plan Replication", "checked": false, "available": true },
    { "code": "21062", "icon": design21, "name": "Commissioning", "checked": false, "available": true },
    { "code": "21065", "icon": design21, "name": "Building Permits", "checked": false, "available": true },
    { "code": "21070", "icon": design21, "name": "Plan Check Fees", "checked": false, "available": true },
    { "code": "21080", "icon": design21, "name": "Planning Fees", "checked": false, "available": true },
    { "code": "21085", "icon": design21, "name": "School District Fees", "checked": false, "available": true },
    { "code": "21090", "icon": design21, "name": "Other Fees", "checked": false, "available": true },
    { "code": "22010", "icon": projsupervision22, "name": "General Contractor - Project Supervision", "checked": false, "available": true },
]

export default lineItemsData;
