// src/components/AddaLineItem/helpers.js

export const getSelectedItemsFromLocal = () => {
  const existingItemsJson = localStorage.getItem('selectedLineItems');
  return existingItemsJson ? JSON.parse(existingItemsJson) : [];
};

export const saveSelectedItemsToLocal = (newItems) => {
  const existingItems = getSelectedItemsFromLocal();
  const mergedItems = [...existingItems, ...newItems];
  const jsonString = JSON.stringify(mergedItems);
  
  localStorage.setItem('selectedLineItems', jsonString);
  
  console.log('Selected line items have been updated and saved to local storage.');
};

export const removeSelectedItemFromLocal = (itemId) => {
  const existingItems = getSelectedItemsFromLocal();
  const updatedItems = existingItems.filter(item => item.id !== itemId);
  localStorage.setItem('selectedLineItems', JSON.stringify(updatedItems));
  
  console.log(`Item with id ${itemId} has been removed from local storage.`);
};

export const getCheckedLineItems = () => {
  const checkedItemsJson = localStorage.getItem('checkedLineItems');
  return checkedItemsJson ? JSON.parse(checkedItemsJson) : [];
};

export const updateCheckedLineItems = (itemId, isChecked) => {
  const checkedItems = getCheckedLineItems();
  if (isChecked) {
    checkedItems.push(itemId);
  } else {
    const index = checkedItems.indexOf(itemId);
    if (index > -1) {
      checkedItems.splice(index, 1);
    }
  }
  localStorage.setItem('checkedLineItems', JSON.stringify(checkedItems));
};

export const removeCheckedLineItem = (itemId) => {
  const checkedItems = getCheckedLineItems();
  const updatedItems = checkedItems.filter(id => id !== itemId);
  localStorage.setItem('checkedLineItems', JSON.stringify(updatedItems));
};