import React, { useState } from "react";
import IconButton from "@mui/material/IconButton";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import useStore from "../../store/store";
import BidBookUploader from "../BidBookUploader";
import uploadproposal from './uploadproposal.svg';

const BidBookCell = ({ rowId }) => {
  const [open, setOpen] = useState(false);
  const { setClickedRowCodeId } = useStore((state) => ({
    setClickedRowCodeId: state.setClickedRowCodeId,
  }));

  const handleClick = () => {
    console.log('BidBookCell clicked for rowId:', rowId);
    setClickedRowCodeId(rowId);
    localStorage.setItem('clickedRowCodeId', rowId);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <div className="flex items-center justify-center relative">
        <div className="relative" onClick={handleClick}>
          <IconButton size="small">
            <img src={uploadproposal} alt="Upload Proposal" />
          </IconButton>
        </div>
      </div>
      <Dialog 
        open={open} 
        onClose={handleClose} 
        maxWidth="md" 
        fullWidth
        disableEscapeKeyDown
        onClick={(e) => e.stopPropagation()}
        PaperProps={{
          style: {
            backgroundColor: 'white',
            boxShadow: 'none',
            border: '1px solid #d3d3d3', // Light gray border
            borderRadius: '8px', // Optional: if you want rounded corners
          },
        }}
        BackdropProps={{
          style: {
            backgroundColor: 'rgba(0, 0, 0, 0.1)', // Lighter background
          },
        }}
      >
        <DialogContent>
          <BidBookUploader onClose={handleClose} rowId={rowId} />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default BidBookCell;