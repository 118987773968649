import React from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputNumber } from 'primereact/inputnumber';
import { Button } from 'primereact/button';
import DeleteRowDialog, { showDeleteConfirmation } from '../DeleteRowDialog';
import useStore from "../../store/store";
import BidBookCell from "../BidBookCell";
import { ColumnHeader, textEditor, numberEditor, tradeBodyTemplate, actionBodyTemplate, icons } from './CustomComponents';
import { useTableLogic, useTableStyles } from './utils';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import styles from './DataTableComponent.module.css';

const { codeIcon, wrenchIcon, descriptionIcon, costIcon, bidBookIcon } = icons;

const DataTableComponent = ({ 
  onRowUpdate, 
  psfRecalculationTrigger, 
  rows, 
  selectedLineItems, 
  setSelectedLineItems, 
  onAddLineItem, 
  onRemoveLineItem,
  projectData,
  selectedProject
}) => {
  const {
    updateRow,
    deleteRow,
    searchTerm,
    nestedSearchTerm,
    expandedRows,
    setExpandedRows
  } = useStore((state) => ({
    updateRow: state.updateRow,
    deleteRow: state.deleteRow,
    searchTerm: state.searchTerm,
    nestedSearchTerm: state.nestedSearchTerm,
    expandedRows: state.expandedRows,
    setExpandedRows: state.setExpandedRows
  }));

  const {
    filteredRows,
    sortedRows,
    totalCost,
    psfCost,
    handleDelete,
    onCellEditComplete,
    onRowExpand,
    onRowCollapse,
    handleSort,
    sortField,
    sortOrder,
    renderRowExpansionTemplate
  } = useTableLogic(rows, searchTerm, nestedSearchTerm, expandedRows, setExpandedRows, deleteRow, updateRow, psfRecalculationTrigger, projectData, selectedProject);
  
  const { tableHeight, tableContainerRef } = useTableStyles();

  const footer = (
    <div className={styles.stickyFooter} style={{ 
      display: 'flex', 
      justifyContent: 'flex-end', 
      alignItems: 'center',
      height: '50px',
      padding: '0 100px',
      fontSize: '16px',
    }}>
      <span style={{ marginRight: '50px', fontWeight: 'bold' }}>
        Total Cost: {totalCost.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
      </span>
      <span style={{ fontWeight: 'bold' }}>
        {psfCost.toLocaleString('en-US', { style: 'currency', currency: 'USD' })} PSF
      </span>
    </div>
  );

  return (
    <div className={styles.tableContainer} style={{ height: '100%' }} ref={tableContainerRef}>
      <DeleteRowDialog />
      <DataTable 
        value={sortedRows.filter(row => row && row.id && row.trade)}
        lazy
        showGridlines
        rowHover
        footer={footer}
        rowClassName={styles.tableRow}
        className={`${styles.customGridlines}`}
        dataKey="id"
        editMode="cell"
        onCellEditComplete={onCellEditComplete}
        expandedRows={expandedRows}
        onRowToggle={(e) => setExpandedRows(e.data)}
        onRowExpand={onRowExpand}
        onRowCollapse={onRowCollapse}
        rowExpansionTemplate={renderRowExpansionTemplate}
        emptyMessage="Add line items to get started."
        scrollable
        scrollHeight={tableHeight}
        style={{ display: 'flex', flexDirection: 'column', height: '100%' }}
        sortField={sortField}
        sortOrder={sortOrder}
        onSort={handleSort}
      >
        <Column expander style={{ width: '3rem' }} headerClassName={styles.stickyHeader} />
        <Column
          field="id"
          header={<ColumnHeader icon={codeIcon} label="Code" />}
          bodyStyle={{ textAlign: 'left' }}
          alignHeader={'left'}
          style={{ width: '125px', flexGrow: 0, flexShrink: 0 }}
          headerClassName={styles.stickyHeader}
        />
        <Column
          field="trade"
          header={<ColumnHeader icon={wrenchIcon} label="Trade" />} 
          body={tradeBodyTemplate}
          editor={(options) => textEditor(options)}
          onCellEditComplete={onCellEditComplete}
          alignHeader={'left'}
          style={{ width: '300px', flexGrow: 0, flexShrink: 0 }}
          headerClassName={styles.stickyHeader}
        />
        <Column
          field="description"
          header={<ColumnHeader icon={descriptionIcon} label="Description" />}
          editor={(options) => textEditor(options)}
          onCellEditComplete={onCellEditComplete}
          alignHeader={'left'}
          style={{ flexGrow: 1 }}
          headerClassName={styles.stickyHeader}
        />
        <Column
          field="cost"
          header={<ColumnHeader icon={costIcon} label="Cost" />}
          className={`${styles.costColumn} costColumn`}
          bodyStyle={{ padding: 0 }}
          body={(rowData) => (
            <InputNumber
              value={rowData.cost}
              mode="currency"
              currency="USD"
              locale="en-US"
              minFractionDigits={2}
              readOnly
              inputClassName="w-full h-full"
            />
          )}
          editor={(options) => numberEditor(options)}
          onCellEditComplete={onCellEditComplete}
          alignHeader={'left'}
          style={{ width: '150px', flexGrow: 0, flexShrink: 0 }}
          headerClassName={styles.stickyHeader}
        />
        <Column
          field="bidBook"
          header={<ColumnHeader icon={bidBookIcon} label="BidBook" />}
          bodyStyle={{ textAlign: 'center' }}
          body={(rowData) => <BidBookCell rowId={rowData.id} />}
          alignHeader={'center'}
          style={{ width: '125px', flexGrow: 0, flexShrink: 0 }}
          headerClassName={styles.stickyHeader}
        />
        <Column 
          body={(rowData) => actionBodyTemplate(rowData, handleDelete)} 
          exportable={false} 
          style={{ width: '3rem', flexGrow: 0, flexShrink: 0 }} 
          headerClassName={styles.stickyHeader}
        />
      </DataTable>
    </div>
  );
};

export default DataTableComponent;