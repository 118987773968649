// src/components/DeleteRowDialog/index.js

import React from 'react';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { confirmDialog } from 'primereact/confirmdialog';
import DeleteIcon from '@mui/icons-material/Delete';
import './DeleteRowDialog.css';

const DeleteRowDialog = () => {
  return (
    <ConfirmDialog />
  );
};

export const showDeleteConfirmation = (onConfirm) => {
  confirmDialog({
    message: (
      <>
        <DeleteIcon style={{ color: "#38824D", fontSize: "2rem", marginBottom: "0.5rem" }} />
        <div>Are you sure you want to delete this row?</div>
      </>
    ),
    acceptClassName: 'custom-confirm-button',
    rejectClassName: 'custom-reject-button',
    accept: onConfirm,
    reject: () => {},
    className: 'custom-confirm-dialog',
    contentClassName: 'custom-confirm-content',
    style: { fontFamily: "SF Pro Display" },
    closeOnEscape: false,
    dismissableMask: false,
    acceptLabel: "Yes",
    rejectLabel: "No",
    position: 'center',
    draggable: false, // Make the dialog non-movable
    footer: (options) => {
      return (
        <div style={{ display: 'flex', justifyContent: 'center', gap: '1rem' }}>
          <button 
            onClick={options.accept} 
            className={options.acceptClassName}
          >
            Yes
          </button>
          <button 
            onClick={options.reject} 
            className={options.rejectClassName}
          >
            No
          </button>
        </div>
      );
    }
  });
};

export default DeleteRowDialog;