import React, { useEffect, useRef, useState, useCallback, useMemo } from "react";
import useStore from "../../store/store";
import { useForm, Controller } from "react-hook-form";
import { shallow } from 'zustand/shallow';
import { Box, Checkbox, Button, Typography, IconButton, TextField, InputAdornment, CircularProgress } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import { styled } from "@mui/material/styles";
import { getSelectedItemsFromLocal, saveSelectedItemsToLocal } from './helpers';
import lineItemsData from './lineItemsData';

const CustomColorTextField = styled(TextField)({
  "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
    borderColor: "#38824D",
  },
  "&.Mui-focused .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
    borderColor: "#38824D",
  },
  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "#38824D",
  },
  "& .MuiOutlinedInput-root": {
    width: "100%",
  },
  "& .MuiInputBase-root": {
    textAlign: "center",
    fontFamily: "'SF Pro Display', sans-serif",
  },
});

export default function AddaLineItem({ disabled }) {
  const { 
    setShowAddLineItemFalse, 
    tapsForAddress, 
    addRow,
    rows
  } = useStore((state) => ({
    setShowAddLineItemFalse: state.setShowAddLineItemFalse,
    tapsForAddress: state.tapsForAddress,
    addRow: state.addRow,
    rows: state.rows
  }), shallow);  // Add shallow comparison

  const [isProjectAvailable] = useState(tapsForAddress.length > 0);
  const [searchTerm, setSearchTerm] = useState("");
  const { control } = useForm();

  const [lineItems, setLineItems] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const componentRef = useRef(null);

  const availableLineItems = useMemo(() => 
    lineItems.filter(item => item && item.available),
    [lineItems]
  );

  const filteredItems = useMemo(() => 
    availableLineItems.filter(item => 
      (item.code && item.code.toLowerCase().includes(searchTerm.toLowerCase())) ||
      (item.name && item.name.toLowerCase().includes(searchTerm.toLowerCase()))
    ),
    [availableLineItems, searchTerm]
  );

  const handleSearch = useCallback((event) => {
    if (!disabled) {
      setSearchTerm(event.target.value);
    }
  }, [disabled]);

  const handleClear = useCallback(() => {
    if (!disabled) {
      setSearchTerm("");
    }
  }, [disabled]);

  const handleSelectAllClick = useCallback(() => {
    if (!disabled) {
      setSelectAll(prevSelectAll => !prevSelectAll);
      setLineItems(prevLineItems => 
        prevLineItems.map(item => ({ ...item, checked: !selectAll }))
      );
    }
  }, [disabled, selectAll]);

  const checkLineItem = useCallback((code) => {
    if (!disabled) {
      setLineItems(prevLineItems => 
        prevLineItems.map(item => 
          item.code === code ? { ...item, checked: !item.checked } : item
        )
      );
    }
  }, [disabled]);

  const handleAddItems = useCallback(() => {
    if (!disabled) {
      setShowAddLineItemFalse();
  
      let selLineItems = lineItems.filter(item => item && item.checked);
      let newItems = [];
  
      selLineItems.forEach((item) => {
        if (item && item.code && item.name) {
          const newItem = {
            id: item.code,
            trade: item.name,
            description: "",
            cost: 0,
            uniqueCaseId: Date.now().toString(),
          };
          addRow(newItem);
          newItems.push(newItem);
          
          setLineItems(prevItems => prevItems.map(prevItem => 
            prevItem.code === item.code ? { ...prevItem, available: false, checked: false } : prevItem
          ));
        }
      });
  
      if (newItems.length > 0) {
        saveSelectedItemsToLocal(newItems);
        console.log("Added items:", newItems);
      }
    }
  }, [disabled, lineItems, addRow, setShowAddLineItemFalse]);

  const uncheckAllLineItems = useCallback(() => {
    if (!disabled) {
      setLineItems(prevLineItems => prevLineItems.map(item => ({ ...item, checked: false })));
      setSelectAll(false);
    }
  }, [disabled]);

  useEffect(() => {
    setIsLoading(true);
    const timer = setTimeout(() => {
      const availableItems = lineItemsData.filter(item => 
        !rows.some(row => row.id === item.code)
      );
      setLineItems(availableItems);
      setIsLoading(false);
    }, 250);

    return () => clearTimeout(timer);
  }, [rows]);

  const lineItemsRef = useRef(lineItems);
  useEffect(() => {
    lineItemsRef.current = lineItems;
    localStorage.setItem('lineItems', JSON.stringify(lineItems));
  }, [lineItems]);

  return (
    <Box
      ref={componentRef}
      sx={{
        width: "90%",
        maxWidth: "500px",
        height: "70vh",
        display: "flex",
        flexDirection: "column",
        position: "relative",
        backgroundColor: "white",
        borderRadius: "8px",
        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
        padding: "20px",
        margin: "0 20px",
        border: '1px solid #e0e0e0', // Added light gray border
        pointerEvents: disabled ? 'none' : 'auto',
      }}
    >
      <IconButton
        onClick={setShowAddLineItemFalse}
        disabled={disabled}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: 'text.secondary',
        }}
      >
        <CloseIcon />
      </IconButton>

      {isProjectAvailable ? (
        <>
          <Typography variant="body1" sx={{ fontFamily: "SF Pro Display", mb: 2, fontWeight:"bold", fontSize:"20px" }}>
            Select line items to add to BudgetBuilder
          </Typography>
          <CustomColorTextField
            variant="outlined"
            size="small"
            value={searchTerm}
            onChange={handleSearch}
            placeholder="Search line items..."
            disabled={disabled}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
              endAdornment: searchTerm ? (
                <InputAdornment position="end">
                  <ClearIcon onClick={handleClear} style={{ cursor: disabled ? 'default' : 'pointer' }} />
                </InputAdornment>
              ) : null,
            }}
            sx={{ mb: 2, width: "100%" }}
          />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              flexGrow: 1,
              width: "100%",
              overflow: 'hidden',
              border: '1px solid #e0e0e0', // Light gray border
              borderRadius: '5px', // 3px corner radius
              paddingTop: 1
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                height: '40px',
                px: 1,
                borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
                backgroundColor: 'white',
              }}
            >
              <Box sx={{ width: '20%', display: 'flex', alignItems: 'center', justifyContent: 'center', pb:1 }}>
                <Typography variant="body2" fontWeight="bold">Cost Code</Typography>
              </Box>
              <Box sx={{ width: '10%' }}></Box>
              <Box sx={{ width: '60%', display: 'flex', alignItems: 'center', pb:1 }}>
                <Typography variant="body2" fontWeight="bold">Trade</Typography>
              </Box>
              <Box sx={{ width: '10%', display: 'flex', alignItems: 'center', justifyContent: 'center', pr: 5.5, pb:1 }}>
                <Typography variant="body2" fontWeight="bold">Selected</Typography>
              </Box>
            </Box>
            <Box sx={{ flexGrow: 1, overflowY: "auto" }}>
              {isLoading ? (
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                  <CircularProgress sx={{ color: '#38824D' }} />
                </Box>
              ) : filteredItems.length === 0 ? (
                <Typography variant="body2" align="center" sx={{ py: 2 }}>
                  No matching line items found.
                </Typography>
              ) : (
                filteredItems.map((item) => (
                  <Box
                    key={item.code}
                    sx={{
                      position: 'relative',
                      display: 'flex',
                      alignItems: 'center',
                      height: '40px',
                      px: 1,
                      '&::after': {
                        content: '""',
                        position: 'absolute',
                        left: 0,
                        right: 0,
                        height: '100%',
                        backgroundColor: 'rgba(0, 0, 0, 0.04)',
                        opacity: 0,
                        transition: 'opacity 0.1s ease',
                        pointerEvents: 'none',
                      },
                      '&:hover::after': {
                        opacity: 1,
                      },
                      cursor: 'pointer',
                    }}
                    onClick={() => checkLineItem(item.code)}
                  >
                    <Box sx={{ width: '20%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                      <Typography variant="body2" color="text.secondary">
                        {item.code}
                      </Typography>
                    </Box>
                    <Box sx={{ width: '10%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                      <Box
                        component="img"
                        src={item.icon}
                        alt=""
                        sx={{
                          width: 18,
                          height: 18,
                          objectFit: "contain",
                        }}
                      />
                    </Box>
                    <Box sx={{ width: '60%', display: 'flex', alignItems: 'center' }}>
                      <Typography variant="body2" color="text.primary" noWrap>
                        {item.name}
                      </Typography>
                    </Box>
                    <Box sx={{ width: '10%', display: 'flex', alignItems: 'center', justifyContent: 'center', pr: 2 }}>
                      <Controller
                        name={`lineItem-${item.code}`}
                        control={control}
                        render={({ field }) => (
                          <Checkbox
                            {...field}
                            checked={item.checked}
                            sx={{
                              padding: 0,
                              color: item.checked ? "#38824D" : "text.disabled",
                              "&.Mui-checked": {
                                color: "#38824D",
                              },
                            }}
                          />
                        )}
                      />
                    </Box>
                  </Box>
                ))
              )}
            </Box>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2, mt: 2, width: '100%' }}>
            <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2, flexWrap: 'wrap' }}>
              {filteredItems.length > 0 && (
                <Button
                  variant="outlined"
                  onClick={handleSelectAllClick}
                  disabled={disabled}
                  sx={{
                    color: "#38824D",
                    borderColor: "#38824D",
                    fontFamily: "SF Pro Display",
                    padding: "0.4rem 1rem",
                    "&:hover": {
                      backgroundColor: "#274d3510",
                      borderColor: "#38824D",
                    },
                    textTransform: "capitalize"
                  }}
                >
                  Select All
                </Button>
              )}
              <Button
                variant="contained"
                color="primary"
                onClick={handleAddItems}
                disabled={disabled}
                sx={{
                  backgroundColor: "#38824D",
                  color: "white",
                  fontFamily: "SF Pro Display",
                  "&:hover": {
                    backgroundColor: "#274d35",
                  },
                  textTransform: "capitalize"
                }}
              >
                Add
              </Button>
              <Button
                variant="outlined"
                onClick={uncheckAllLineItems}
                disabled={disabled}
                sx={{
                  color: "#38824D",
                  borderColor: "#38824D",
                  fontFamily: "SF Pro Display",
                  "&:hover": {
                    backgroundColor: "#274d3510",
                    borderColor: "#38824D",
                  },
                  textTransform: "capitalize"
                }}
              >
                Clear
              </Button>
            </Box>
          </Box>
        </>
      ) : (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
          }}
        >
          <Typography variant="h6" align="center" color="text.secondary">
            Please create a new project before adding line items.
          </Typography>
        </Box>
      )}
    </Box>
  );
}