import React from "react";
import { Link } from "react-router-dom";
import { useUser } from "@clerk/clerk-react";
import { SignInButton, UserButton } from "@clerk/clerk-react";
import CogIcon from "./icons/cog.svg";
import DotsIcon from "./icons/9 dots.svg";
import BBLogo from "./icons/budgetbuilder Logo Green.svg";

export default function BBNavbar() {
  const { isSignedIn } = useUser();
  const buttonStyle = "bg-gray-700 text-transparent bg-clip-text hover:bg-teal-900 p-3 pt-1 rounded-md font-bold text-base transition duration-300 ease-in-out";
  const iconStyle = "w-5 h-5 text-gray-700 hover:text-teal-900 cursor-pointer";

  return (
    <header className="mt-2 flex justify-center">
      <div className="bg-white rounded-t-[10px] w-[calc(100%-10px)] after:content-[''] after:block after:w-[calc(100%-32px)] after:mx-auto after:border-b after:border-gray-200">
        <nav className="flex justify-between items-center w-full pl-5 pr-8 py-3">
          <div className="flex justify-start items-center">
            <a
              href="https://home.consight.co"
              className="text-gray-700 p-2 rounded-md font-bold text-4xl"
            >
              <img src={BBLogo} alt="BBLogo" className="h-12" />
            </a>
          </div>
          <div className="flex items-center">
            {isSignedIn ? (
              <div className="flex items-center h-full space-x-8">
                <img src={CogIcon} alt="Settings" className={iconStyle} />
                <img src={DotsIcon} alt="Menu" className={iconStyle} />
                <UserButton 
                  mode="modal"
                  appearance={{
                    elements: {
                      avatarBox: "w-10 h-10"
                    }
                  }}
                />
              </div>
            ) : (
              <SignInButton mode="modal">
                <button className={buttonStyle}>
                  LOG IN
                </button>
              </SignInButton>
            )}
          </div>
        </nav>
      </div>
    </header>
  );
}