import React, { useState, useEffect } from "react";
import useStore from "../../store/store";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import { styled } from "@mui/material/styles";

const CustomColorTextField = styled(TextField)({
  "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
    borderColor: "#38824D",
  },
  "&.Mui-focused .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
    borderColor: "#38824D",
  },
  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "#38824D",
  },
  "& .MuiOutlinedInput-root": {
    width: "500px",
  },
  "& .MuiInputBase-root": {
    textAlign: "center",
    fontFamily: "'SF Pro Display', sans-serif",
  },
  "&.Mui-disabled .MuiOutlinedInput-notchedOutline": {
    borderColor: "#A9A9A9",
  },
  "&.Mui-disabled .MuiInputBase-root": {
    color: "#A9A9A9",
  },
});

export default function SearchTradeCostCode({ disabled }) {
  const { setSearchTerm, searchTerm, setNestedSearchTerm, nestedSearchTerm } = useStore((state) => ({
    setSearchTerm: state.setSearchTerm,
    searchTerm: state.searchTerm,
    setNestedSearchTerm: state.setNestedSearchTerm,
    nestedSearchTerm: state.nestedSearchTerm,
  }));
  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    setSearchValue(searchTerm || nestedSearchTerm || "");
  }, [searchTerm, nestedSearchTerm]);

  const handleSearch = (event) => {
    if (!disabled) {
      const value = event.target.value;
      setSearchValue(value);
      setSearchTerm(value);
      setNestedSearchTerm(value);
      console.log("Search term updated:", value);
    }
  };

  const handleClear = () => {
    if (!disabled) {
      setSearchValue("");
      setSearchTerm("");
      setNestedSearchTerm("");
      console.log("Search cleared");
    }
  };

  return (
    <CustomColorTextField
      variant="outlined"
      size="small"
      value={searchValue}
      onChange={handleSearch}
      placeholder="Search all fields..."
      disabled={disabled}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
        endAdornment: searchValue ? (
          <InputAdornment position="end">
            <ClearIcon 
              onClick={handleClear} 
              style={{ cursor: disabled ? 'default' : 'pointer' }} 
            />
          </InputAdornment>
        ) : null,
      }}
      sx={{
        opacity: 1,
        pointerEvents: disabled ? 'none' : 'auto',
      }}
    />
  );
}