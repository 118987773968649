import * as React from "react";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import NoteAddOutlinedIcon from "@mui/icons-material/NoteAddOutlined";
import { grey } from "@mui/material/colors";
import useStore from "../../store/store";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DownloadIcon from '@mui/icons-material/Download';

export default function ActionList() {
  const { showActionList, setShowActionListTrue, setShowActionListFalse, } = useStore((state) => ({
    showActionList: state.showActionList,
    setShowActionListTrue: state.setShowActionListTrue,
    setShowActionListFalse: state.setShowActionListFalse,
  }));

  const ref = React.useRef(null);

  const handleClickAway = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setShowActionListFalse();
    }
  };

  React.useEffect(() => {
    document.addEventListener('mousedown', handleClickAway);
    return () => {
      document.removeEventListener('mousedown', handleClickAway);
    };
  }, []);

  const iconSize = {
    minWidth: "36px",
  };

  const disabledStyle = {
    color: grey[400],
    cursor: 'not-allowed',
    pointerEvents: 'none',
    '&:hover': { bgcolor: 'transparent' },
  };

  const renderListItem = (icon, text, onClick) => (
    <ListItemButton
      onClick={onClick}
      sx={{
        ...disabledStyle,
        padding: "6px 12px",
      }}
      disabled
    >
      <ListItemIcon sx={{ ...iconSize, color: 'inherit' }}>
        {icon}
      </ListItemIcon>
      <ListItemText
        primary={text}
        primaryTypographyProps={{
          style: {
            fontFamily: "SF Pro Display",
            fontWeight: 400,
            fontSize: "0.8rem",
          },
        }}
      />
    </ListItemButton>
  );

  return (
    <List
      component="nav"
      aria-label="actions"
      sx={{
        width: "100%",
        maxWidth: 360,
        bgcolor: "background.paper",
        border: `1px solid ${grey[300]}`,
        borderRadius: "4px",
        overflow: "hidden",
        position: "relative",
        padding: "8px",
      }}
      ref={ref}
    >
      {renderListItem(
        <NoteAddOutlinedIcon />,
        "Make a one-click subcontract",
        () => console.log("Make a one-click subcontract")
      )}
      {renderListItem(
        <RemoveCircleOutlineIcon />,
        "Delete line item",
        () => console.log("Delete line item")
      )}
      {renderListItem(
        <ContentCopyIcon />,
        "Duplicate line item",
        () => console.log("Duplicate line item")
      )}
      {renderListItem(
        <DownloadIcon />,
        "Export Trade to Excel",
        () => console.log("Export Trade to Excel")
      )}
    </List>
  );
}