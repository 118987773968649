import React from 'react';
import * as XLSX from 'xlsx';
import Button from '@mui/material/Button';
import { PrimeIcons } from 'primereact/api';

const ExportTable = ({ fileName, rows, projectInfo, disabled }) => {
  const formatCurrency = (value) => {
    if (typeof value === 'number' || (typeof value === 'string' && !isNaN(parseFloat(value)))) {
      return parseFloat(value).toLocaleString('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 2 });
    }
    return value;
  };

  const sanitizeSheetName = (name) => {
    return name.replace(/[\\*?:/[\]]/g, '').slice(0, 31);
  };

  const getPSTDate = () => {
    const options = {
      timeZone: 'America/Los_Angeles',
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    };
    return new Intl.DateTimeFormat('en-US', options).format(new Date()).replace(/\//g, '-');
  };

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const formatFieldName = (key) => {
    return key.split(/(?=[A-Z])/).map(capitalizeFirstLetter).join(' ');
  };

  const exportToExcel = () => {
    if (disabled) return;
    const workbook = XLSX.utils.book_new();

    // Create Project Information sheet (transposed)
    const transposedProjectInfo = Object.entries(projectInfo)
      .map(([key, value]) => [
        formatFieldName(key),
        key === 'totalBuildingSqft' || key === 'totalLandSqft' ? 
          (value ? `${value} sqft` : '') :
          value
      ]);
    const projectInfoSheet = XLSX.utils.aoa_to_sheet(transposedProjectInfo);
    XLSX.utils.book_append_sheet(workbook, projectInfoSheet, sanitizeSheetName("Project Information"));

    // Auto-size columns for Project Information sheet
    projectInfoSheet['!cols'] = [
      { wch: Math.max(...transposedProjectInfo.map(row => row[0].length)) + 2 },
      { wch: Math.max(...transposedProjectInfo.map(row => (row[1] ? row[1].toString().length : 0))) + 2 }
    ];

    // Create Budget sheet
    const budgetData = rows.map(row => ({
      "Code": row.id,
      "Trade": row.trade,
      "Description": row.description,
      "Cost": formatCurrency(row.cost)
    }));

    const budgetSheet = XLSX.utils.json_to_sheet(budgetData);

    // Calculate Total Cost and $PSF
    const totalCost = rows.reduce((sum, row) => sum + (parseFloat(row.cost) || 0), 0);
    const totalBuildingSqft = parseFloat(projectInfo.totalBuildingSqft) || 1; // Prevent division by zero
    const psfCost = totalCost / totalBuildingSqft;

    // Add Total Cost and $PSF to the sheet
    const totalCostRow = ["Total Cost", "", "", formatCurrency(totalCost)];
    const psfCostRow = ["$PSF", "", "", formatCurrency(psfCost)];

    // Get the current range of the sheet
    const range = XLSX.utils.decode_range(budgetSheet['!ref']);
    const lastRow = range.e.r;

    // Add empty row, Total Cost row, and $PSF row
    XLSX.utils.sheet_add_aoa(budgetSheet, [
      ["", "", "", ""],
      totalCostRow,
      psfCostRow
    ], { origin: lastRow + 1 });

    XLSX.utils.book_append_sheet(workbook, budgetSheet, sanitizeSheetName("Budget Summary"));

    // Auto-size columns for Budget sheet
    const newRange = XLSX.utils.decode_range(budgetSheet['!ref']);
    for (let C = newRange.s.c; C <= newRange.e.c; ++C) {
      let max_width = 0;
      for (let R = newRange.s.r; R <= newRange.e.r; ++R) {
        const cell = budgetSheet[XLSX.utils.encode_cell({c: C, r: R})];
        if (cell && cell.v) {
          const width = (cell.v.toString().length + 2) * 1.2;
          if (width > max_width) max_width = width;
        }
      }
      budgetSheet['!cols'] = budgetSheet['!cols'] || [];
      budgetSheet['!cols'][C] = { wch: max_width };
    }

    // Create individual sheets for each row's nested table
    rows.forEach((row) => {
      if (row.nestedData && row.nestedData.length > 0) {
        const sheetName = sanitizeSheetName(`${row.id} ${row.trade}`);
        let nestedData = row.nestedData;

        if (typeof nestedData === 'string') {
          try {
            nestedData = JSON.parse(nestedData);
          } catch (error) {
            console.error('Error parsing nested data:', error);
            return;
          }
        }

        if (Array.isArray(nestedData) && nestedData.length > 0) {
          // Extract all unique line items
          const lineItems = nestedData.find(item => item.name === "Line Items")?.items || [];
          const unitOfMeasure = nestedData.find(item => item.name === "Unit of Measure")?.items || [];
          const quantityRequired = nestedData.find(item => item.name === "Quantity Required")?.quantities || [];
          const companies = nestedData.filter(item => !["Line Items", "Unit of Measure", "Quantity Required"].includes(item.name));

          // Prepare the data for the sheet
          const sheetData = [
            ["Cost Code", "Trade", "Description", "Cost"],
            [row.id, row.trade, row.description, formatCurrency(row.cost)],
            ["", "", "", ""],
            ["Line Item", "Unit of Measure", "Quantity Required", ...companies.map(company => company.name)],
            ...lineItems.map((item, index) => [
              item,
              unitOfMeasure[index] || "",
              quantityRequired[index] || "",
              ...companies.map(company => company.quantities[index] || "")
            ])
          ];

          const sheet = XLSX.utils.aoa_to_sheet(sheetData);
          XLSX.utils.book_append_sheet(workbook, sheet, sheetName);

          // Auto-size columns
          const range = XLSX.utils.decode_range(sheet['!ref']);
          for (let C = range.s.c; C <= range.e.c; ++C) {
            let max_width = 0;
            for (let R = range.s.r; R <= range.e.r; ++R) {
              const cell = sheet[XLSX.utils.encode_cell({c: C, r: R})];
              if (cell && cell.v) {
                const width = (cell.v.toString().length + 2) * 1.2;
                if (width > max_width) max_width = width;
              }
            }
            sheet['!cols'] = sheet['!cols'] || [];
            sheet['!cols'][C] = { wch: max_width };
          }
        }
      }
    });

    // Use PST date in the file name
    const pstDate = getPSTDate();
    const sanitizedFileName = sanitizeSheetName(fileName || 'Untitled Project');
    const fileNameWithPSTDate = `${sanitizedFileName} - Budget Summary - ${pstDate}.xlsx`;

    XLSX.writeFile(workbook, fileNameWithPSTDate);
  };

  return (
    <Button
      variant="outlined"
      color="primary"
      onClick={exportToExcel}
      disabled={disabled}
      sx={{
        fontFamily: "SF Pro Display",
        color: disabled ? "#A9A9A9" : "#38824D",
        textTransform: "capitalize",
        borderColor: disabled ? "#A9A9A9" : "#38824D",
        fontWeight: "bold",
        borderRadius: "5px",
        minWidth: '40px',
        width: '60px',
        height: '40px',
        padding: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        "&:hover": {
          borderColor: disabled ? "#A9A9A9" : "#38824D",
          backgroundColor: disabled ? "transparent" : "#38824D20",
        },
      }}
      title="Export to Excel"
    >
      <i 
        className={PrimeIcons.FILE_EXCEL} 
        style={{ 
          color: disabled ? "#A9A9A9" : "#38824D", 
          fontSize: '20px'
        }} 
      />
    </Button>
  );
};

export default ExportTable;