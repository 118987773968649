import { Outlet, useNavigate, useLocation } from "react-router-dom";
import useStore from "./store/store";
import { useUser } from "@clerk/clerk-react";
import { useEffect } from "react";

function App() {
  const { isLoaded, isSignedIn, user } = useUser();
  const { updateUserInfo, checkSubscription } = useStore();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const createOrUpdateUser = async () => {
      if (isSignedIn && user) {
        const apiUrl = process.env.NODE_ENV === 'production'
          ? process.env.REACT_APP_API_URL_PROD
          : process.env.REACT_APP_API_URL_DEV;

        try {
          const response = await fetch(`${apiUrl}/api/users`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              clerk_id: user.id,
              email: user.primaryEmailAddress.emailAddress,
              first_name: user.firstName || '',
              last_name: user.lastName || '',
            }),
          });

          if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData.message || 'Failed to create or update user');
          }

          const data = await response.json();

          if (data && data.id) {
            updateUserInfo(data);
            
            // Only check subscription for /budgetbuilder route
            if (location.pathname === '/budgetbuilder') {
              const hasActiveSubscription = await checkSubscription(user.primaryEmailAddress.emailAddress);
              
              if (!hasActiveSubscription) {
                navigate('/subscription-required');
              }
            }
          } else {
            console.error('Invalid user data received:', data);
          }
        } catch (error) {
          console.error('Error creating or updating user:', error.message);
        }
      }
    };

    createOrUpdateUser();
  }, [isSignedIn, user, updateUserInfo, checkSubscription, navigate, location]);

  return (
    <div className="flex flex-col">
      <div className="flex flex-row h-screen">
        <div style={{ width: "100%" }} className="chartsbaseleft pt-2 pl-2 pr-2 sm:pl-2">
          <Outlet />
        </div>
      </div>
    </div>
  );
}

export default App;