import React, { useState, useCallback } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { removeSelectedItemFromLocal, removeCheckedLineItem } from '../AddaLineItem/helpers';
import lineItemsData from '../AddaLineItem/lineItemsData';
import styles from './DataTableComponent.module.css';

export const debounce = (func, wait) => {
  let timeout;
  return function executedFunction(...args) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
};

export const useTableLogic = (rows, searchTerm, nestedSearchTerm, expandedRows, setExpandedRows, deleteRow, updateRow, psfRecalculationTrigger, projectData, selectedProject) => {
  const [sortField, setSortField] = React.useState(null);
  const [sortOrder, setSortOrder] = React.useState(null);
  const [editingCell, setEditingCell] = useState(null);

  const totalBuildingSqFt = React.useMemo(() => {
    if (projectData[selectedProject] && projectData[selectedProject].projectInfo) {
      return parseFloat(projectData[selectedProject].projectInfo.totalBuildingSqft) || 0;
    }
    return 0;
  }, [projectData, selectedProject, psfRecalculationTrigger]);

  const filteredRows = React.useMemo(() => {
    return rows.filter((row) => {
      if (!row || !row.id || !row.trade) return false;
      const searchTermLower = (searchTerm || "").toLowerCase();
      const nestedSearchTermLower = (nestedSearchTerm || "").toLowerCase();
      
      // Search in main row data
      const mainRowMatch = Object.entries(row).some(([key, value]) => {
        if (value === null || value === undefined) return false;
        const stringValue = String(value).toLowerCase();
        return stringValue.includes(searchTermLower);
      });

      if (mainRowMatch) return true;

      // Search in nested data
      if (row.nestedData) {
        const nestedData = Array.isArray(row.nestedData) ? row.nestedData : JSON.parse(row.nestedData);
        return nestedData.some(item => {
          if (Array.isArray(item.items)) {
            return item.items.some(subItem => String(subItem).toLowerCase().includes(nestedSearchTermLower));
          }
          if (Array.isArray(item.quantities)) {
            return item.quantities.some(quantity => String(quantity).toLowerCase().includes(nestedSearchTermLower));
          }
          return false;
        });
      }

      return false;
    });
  }, [rows, searchTerm, nestedSearchTerm]);

  const totalCost = React.useMemo(() => {
    return filteredRows.reduce((sum, row) => sum + (parseFloat(row.cost) || 0), 0);
  }, [filteredRows]);

  const psfCost = React.useMemo(() => {
    return totalBuildingSqFt > 0 ? totalCost / totalBuildingSqFt : 0;
  }, [totalCost, totalBuildingSqFt]);

  const handleDelete = (rowData) => {
    deleteRow(rowData.id);
    removeSelectedItemFromLocal(rowData.id);
    removeCheckedLineItem(rowData.id);
  };

  const onCellEditComplete = (e) => {
    let { rowData, newValue, field, originalEvent: event } = e;

    if (field === 'cost') {
        if (typeof newValue === 'string') {
            newValue = parseFloat(newValue.replace(/[^0-9.-]+/g, ""));  
        }
        if (isNaN(newValue) || newValue < 0) {
            event.preventDefault();
            return;
        }
    } else if (field !== 'description' && newValue.trim().length === 0) {
        event.preventDefault();
        return;
    }

    let updatedRowData = {...rowData, [field]: newValue};
    updateRow(updatedRowData.id, { [field]: newValue });
};

const onNestedCellEditInit = (e, rowData) => {
    setEditingCell({ rowId: rowData.id, field: e.field, value: e.value });
};

const onNestedCellEditCancel = (e) => {
    setEditingCell(null);
};

const onNestedCellEditComplete = (e, rowData) => {
  if (!editingCell) return;

  let { newValue } = e;
  const { rowId, field } = editingCell;
  const [type, index] = field.split('.');

  let updatedNestedData = JSON.parse(JSON.stringify(rowData.nestedData));

  if (type === 'lineItem') {
    const lineItemsObject = updatedNestedData.find(item => item.name === "Line Items");
    if (lineItemsObject && lineItemsObject.items) {
      lineItemsObject.items[index] = newValue;
    }
  } else if (type === 'unitOfMeasure') {
    const unitOfMeasureObject = updatedNestedData.find(item => item.name === "Unit of Measure");
    if (unitOfMeasureObject && unitOfMeasureObject.items) {
      unitOfMeasureObject.items[index] = newValue;
    }
  } else {
    const targetObject = updatedNestedData.find(item => item.name === type);
    if (targetObject && targetObject.quantities) {
      targetObject.quantities[index] = newValue;
    }
  }

  // Recalculate Leveled Price
  const companies = updatedNestedData.filter(item => !["Line Items", "Unit of Measure", "Quantity Required"].includes(item.name));
  companies.forEach(company => {
    let total = 0;
    let percentageIncrease = 0;

    company.quantities.forEach((quantity, idx) => {
      if (idx < company.quantities.length - 1) { // Exclude the last item (Leveled Price)
        const dollarMatch = quantity.match(/\$\s*([\d.]+)/);
        const percentMatch = quantity.match(/(\d+(\.\d+)?)\s*%/);
        
        if (dollarMatch) {
          total += parseFloat(dollarMatch[1]);
        } else if (percentMatch) {
          percentageIncrease += parseFloat(percentMatch[1]);
        }
      }
    });

    // Apply percentage increase
    if (percentageIncrease > 0) {
      total += (total * percentageIncrease / 100);
    }

    company.quantities[company.quantities.length - 1] = `$${total.toFixed(2)}`;
  });

  // Update the row with the new nested data
  updateRow(rowId, { nestedData: updatedNestedData });

  // Save to localStorage
  saveNestedDataToLocalStorage(rowId, updatedNestedData);

  setEditingCell(null);
};

const saveNestedDataToLocalStorage = (rowId, nestedData) => {
    const projectData = JSON.parse(localStorage.getItem('projectData') || '{}');
    const lastSelectedProject = localStorage.getItem('lastSelectedProject');
    
    if (!projectData[lastSelectedProject]) {
        projectData[lastSelectedProject] = {};
    }
    
    if (!projectData[lastSelectedProject].lineItems) {
        projectData[lastSelectedProject].lineItems = {};
    }
    
    projectData[lastSelectedProject].lineItems[rowId] = {
        ...projectData[lastSelectedProject].lineItems[rowId],
        nestedData: nestedData
    };

    localStorage.setItem('projectData', JSON.stringify(projectData));
};

  const onRowExpand = (event) => {
    console.log('Row expanded:', event.data);
    setExpandedRows({...expandedRows, [event.data.id]: true});
  };

  const onRowCollapse = (event) => {
    console.log('Row collapsed:', event.data);
    const newExpandedRows = {...expandedRows};
    delete newExpandedRows[event.data.id];
    setExpandedRows(newExpandedRows);
  };

  const handleSort = (e) => {
    setSortField(e.sortField);
    setSortOrder(e.sortOrder);
  };

  const sortedRows = React.useMemo(() => {
    let sorted = [...filteredRows];
    if (sortField && sortOrder) {
      sorted.sort((a, b) => {
        let aValue = a[sortField];
        let bValue = b[sortField];
        if (sortField === 'id') {
          aValue = aValue.toUpperCase();
          bValue = bValue.toUpperCase();
        }
        if (aValue < bValue) {
          return sortOrder === 1 ? -1 : 1;
        }
        if (aValue > bValue) {
          return sortOrder === 1 ? 1 : -1;
        }
        return 0;
      });
    }
    return sorted;
  }, [filteredRows, sortField, sortOrder]);
  
  const renderRowExpansionTemplate = (data) => {
    console.log('Row expansion data:', data);
    let nestedData = data.nestedData || [];
    console.log('Original nested data:', nestedData);

    if (typeof nestedData === 'string') {
      try {
        nestedData = JSON.parse(nestedData);
      } catch (error) {
        console.error('Error parsing nested data:', error);
        return <div>Error parsing data. Please ensure the data is in valid JSON format.</div>;
      }
    }

    if (!Array.isArray(nestedData) || nestedData.length === 0) {
      return <div>Open your BidBook to upload proposals and level your bids.</div>;
    }

    const lineItems = nestedData.find(item => item.name === "Line Items")?.items || [];
    const unitOfMeasure = nestedData.find(item => item.name === "Unit of Measure")?.items || [];
    const quantityRequired = nestedData.find(item => item.name === "Quantity Required")?.quantities || [];
    const companies = nestedData.filter(item => !["Line Items", "Unit of Measure", "Quantity Required"].includes(item.name));

    if (companies.length === 0) {
      return <div>No company data available.</div>;
    }

    const headers = ['Line Item', 'Unit of Measure', 'Quantity Required', ...companies.map(company => company.name)];

  // Pre-calculate Leveled Price for each company
  companies.forEach(company => {
    let total = 0;
    let percentageIncrease = 0;

    company.quantities.forEach((quantity, idx) => {
      if (idx < company.quantities.length - 1) { // Exclude the last item (Leveled Price)
        const dollarMatch = quantity.match(/\$\s*([\d.]+)/);
        const percentMatch = quantity.match(/(\d+(\.\d+)?)\s*%/);
        
        if (dollarMatch) {
          total += parseFloat(dollarMatch[1]);
        } else if (percentMatch) {
          percentageIncrease += parseFloat(percentMatch[1]);
        }
      }
    });

    // Apply percentage increase
    if (percentageIncrease > 0) {
      total += (total * percentageIncrease / 100);
    }

    company.quantities[company.quantities.length - 1] = `$${total.toFixed(2)}`;
  });

    const rows = lineItems.map((item, index) => [
      {
        value: item,
        editable: true,
        field: `lineItem.${index}`
      },
      {
        value: unitOfMeasure[index] || '',
        editable: true,
        field: `unitOfMeasure.${index}`
      },
      {
        value: quantityRequired[index] || '',
        editable: true,
        field: `Quantity Required.${index}`
      },
      ...companies.map(company => ({
        value: company.quantities[index] || '',
        editable: true,
        field: `${company.name}.${index}`
      }))
    ]);

    const columnCount = headers.length;
    const fixedColumnWidths = {
      'Line Item': '400px',
      'Unit of Measure': '100px',
      'Quantity Required': '100px'
    };

    const totalFixedWidth = Object.values(fixedColumnWidths).reduce((sum, width) => sum + parseInt(width), 0);
    const subcontractorColumnCount = columnCount - Object.keys(fixedColumnWidths).length;
    const remainingWidth = `calc((100% - ${totalFixedWidth}px) / ${subcontractorColumnCount})`;

    return (
      <DataTable value={rows} className={styles.nestedTable}>
        {headers.map((header, index) => (
          <Column 
            key={index} 
            field={index.toString()}
            header={header}
            body={(rowData) => {
              const cellData = rowData[index];
              const isBaseBid = rowData[0].value === 'Base Bid';
              const isLeveledPrice = rowData[0].value === 'Leveled Price';
              let content = cellData.value != null ? cellData.value.toString() : '';

              if ((isBaseBid || isLeveledPrice) && index > 2 && !isNaN(parseFloat(content))) {
                content = `$${parseFloat(content).toFixed(2)}`;
              }

              const isEditing = editingCell && 
                                editingCell.rowId === data.id && 
                                editingCell.field === cellData.field;

              return (
                <div className={styles.nestedCellWrapper}>
                  <div
                    className={`
                      ${styles.nestedEditableCell} 
                      ${isEditing ? styles.nestedEditingCell : ''}
                      ${isBaseBid ? styles.baseBidRow : ''}
                      ${isLeveledPrice ? styles.leveledPriceRow : ''}
                      ${!content ? styles.emptyCell : ''}
                    `}
                    onClick={() => !isEditing && !isLeveledPrice && !isBaseBid && onNestedCellEditInit({ field: cellData.field, value: content }, data)}
                  >
                    {isEditing ? (
                      <input
                        type="text"
                        value={editingCell.value}
                        onChange={(e) => setEditingCell({...editingCell, value: e.target.value})}
                        onBlur={(e) => onNestedCellEditComplete({ newValue: e.target.value }, data)}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter') {
                            onNestedCellEditComplete({ newValue: e.target.value }, data);
                          } else if (e.key === 'Escape') {
                            onNestedCellEditCancel(e);
                          }
                        }}
                        autoFocus
                        className={`
                          ${styles.nestedEditInput}
                          ${isBaseBid ? styles.baseBidInput : ''}
                          ${isLeveledPrice ? styles.leveledPriceInput : ''}
                        `}
                      />
                    ) : (
                      <span style={{ fontWeight: (isBaseBid || isLeveledPrice) ? 'bold' : 'normal' }}>{content || '\u00A0'}</span>
                    )}
                  </div>
                </div>
              );
            }}
            style={{ width: fixedColumnWidths[header] || remainingWidth }}
          />
        ))}
      </DataTable>
    );
  };

return {
  filteredRows,
  sortedRows,
  totalCost,
  psfCost,
  handleDelete,
  onCellEditComplete,
  onRowExpand,
  onRowCollapse,
  handleSort,
  sortField,
  sortOrder,
  renderRowExpansionTemplate
};
};

export const useTableStyles = () => {
  const [tableHeight, setTableHeight] = React.useState('calc(100% - 50px)');
  const tableContainerRef = React.useRef(null);

  React.useEffect(() => {
    const handleResize = debounce(() => {
      if (tableContainerRef.current) {
        const containerHeight = tableContainerRef.current.clientHeight;
        const footerHeight = 50;
        const newTableHeight = containerHeight - footerHeight;
        setTableHeight(`${newTableHeight}px`);
      }
    }, 100);

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return { tableHeight, tableContainerRef };
};