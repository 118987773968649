import React from 'react';
import { Dropdown } from 'primereact/dropdown';
import Button from '@mui/material/Button';
import DomainAddIcon from '@mui/icons-material/DomainAdd';

const ProjectSelector = ({ selectedProject, onProjectChange, projects, onNewProject }) => {
  const greenColor = '#38824D';
  const darkGreenColor = '#274d35';
  const greenColorWithOpacity = '#38824D30';
  const black = '#000000';
  const gray = '#d3d3d340';

  const dropdownStyles = `
    .p-dropdown {
      height: 42px;
      display: flex;
      align-items: center;
      padding-left: 16px;
      font-size: 12px;
      border: 1px solid #ced4da;
      transition: box-shadow 0.2s;
    }
    .p-dropdown:hover, .p-dropdown:focus, .p-dropdown.p-focus {
      border-color: ${greenColor};
      box-shadow: 0 0 0 2px ${greenColorWithOpacity};
    }
      
    .p-dropdown-panel .p-dropdown-items .p-dropdown-item {
      padding: 10px 16px;
    }
    .p-dropdown-panel .p-dropdown-items .p-dropdown-item:hover {
      background-color: ${gray} !important;
    }
    .p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
      background-color: ${greenColorWithOpacity} !important;
      color: ${black} !important;
    }

    .p-dropdown-filter-container {
      position: relative;
      display: flex;
      align-items: center;
      padding: 8px;
      background-color: #f4f4f4;
      border-bottom: 1px solid #ced4da;
    }

    .p-dropdown-filter {
      width: 100%;
      height: 32px;
      padding-left: 16px;
      padding-right: 30px;
      font-size: 12px;
      border: none;
      background-color: #fff;
      transition: box-shadow 0.2s;
    }
    .p-dropdown-filter:hover, .p-dropdown-filter:focus {
        box-shadow: 0 0 0 2px #38824D90;
    }

    .p-dropdown-filter-icon {
      position: absolute;
      right: 16px;
      top: 50%;
      transform: translateY(-50%);
      color: #808080;
    }

    .p-dropdown-panel {
      border: 1px solid #d3d3d3;
      border-radius: 5px;
    }
  `;

  return (
    <div className="flex items-center w-full">
      <Button 
        onClick={onNewProject}
        sx={{
          backgroundColor: greenColor,
          borderColor: greenColor, 
          color: "white",
          fontWeight: "bold",
          paddingLeft: "16px",
          paddingRight: "16px",
          marginRight: "16px",
          height: "40px",
          "&:hover": {
            backgroundColor: darkGreenColor,
            borderColor: darkGreenColor,
          },
        }}
        startIcon={<DomainAddIcon />}
      >
        <span className="capitalize">New Project</span>
      </Button>
      <style>{dropdownStyles}</style>
      <Dropdown
        value={selectedProject}
        options={projects}
        filter
        onChange={(e) => onProjectChange(e.value)}
        optionLabel="label"
        placeholder="Select a project"
        className="w-64"
      />
    </div>
  );
};

export default ProjectSelector;