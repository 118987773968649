import React from 'react';
import { UserButton } from "@clerk/clerk-react";
import ConsightLogo from '../components/Navbars/icons/consightlogo.svg';

const SubscriptionRequired = () => {
  const buyNowLink = process.env.NODE_ENV === 'production'
    ? 'https://billing.consight.co/b/bIYg1M13W7uRdagcMN'
    : 'https://buy.stripe.com/test_00geWC3ZE1nY56U5kk';

  return (
    <div className="relative min-h-screen bg-[#e8f1f2]">
      <a href="https://home.consight.co" className="absolute top-8 left-8">
        <img 
          src={ConsightLogo} 
          alt="Consight Logo" 
          className="w-48 h-auto transition-opacity duration-300 hover:opacity-80"
        />
      </a>
      <div className="absolute top-8 right-8">
        <UserButton 
          appearance={{
            elements: {
              avatarBox: "w-12 h-12"
            }
          }}
        />
      </div>
      <div className="flex flex-col items-center justify-center min-h-screen">
        <h1 className="text-4xl font-bold mb-4">Subscription Required</h1>
        <p className="text-xl mb-8">You need an active Consight subscription to access BudgetBuilder.</p>
        <div className="flex space-x-4 mb-4">
          <a 
            href={buyNowLink}
            className="bg-[#38824D] hover:bg-[#274d35] text-white font-bold py-2 px-4 rounded transition duration-300"
            target="_blank"
            rel="noopener noreferrer"
          >
            Buy now
          </a>
          <a 
            href="https://home.consight.co/contact"
            className="bg-[#38824D] hover:bg-[#274d35] text-white font-bold py-2 px-4 rounded transition duration-300"
            target="_blank"
            rel="noopener noreferrer"
          >
            Contact Sales
          </a>
        </div>
        <div className="flex flex-col items-center space-y-4">
          <a 
            href="/budgetbuilder" 
            className="text-[#38824D] hover:text-[#274d35] transition duration-300"
          >
            Go to BudgetBuilder
          </a>
          <a 
            href="https://home.consight.co" 
            className="text-[#38824D] hover:text-[#274d35] transition duration-300"
          >
            Return to home page
          </a>
        </div>
      </div>
    </div>
  );
};

export default SubscriptionRequired;