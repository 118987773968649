import React from 'react';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { Button } from 'primereact/button';
import lineItemsData from '../AddaLineItem/lineItemsData';
import { showDeleteConfirmation } from '../DeleteRowDialog';
import styles from './DataTableComponent.module.css';

// Import icons
import wrenchIcon from './icons/wrench.svg';
import codeIcon from './icons/code.svg';
import descriptionIcon from './icons/description.svg';
import costIcon from './icons/cost.svg';
import bidBookIcon from './icons/bidbook.svg';

const columnHeaderStyle = {
  display: 'flex',
  alignItems: 'center',
  height: '20px',
  gap: '6px',
};

const columnIconStyle = {
  height: '20px',
  verticalAlign: 'middle',
  display: 'inline-block',
};

export const ColumnHeader = ({ icon, label }) => (
  <div style={columnHeaderStyle}>
    <img src={icon} alt={`${label} Icon`} style={columnIconStyle} />
    <span>{label}</span>
  </div>
);

export const textEditor = (options) => {
  return (
    <InputText
      type="text"
      value={options.value}
      onChange={(e) => options.editorCallback(e.target.value)}
      onKeyDown={(e) => e.key === 'Enter' && options.editorCallback(e.target.value)}
    />
  );  
};

export const numberEditor = (options) => {
  return (
    <InputNumber
      value={options.value}
      onValueChange={(e) => options.editorCallback(e.value)}
      mode="currency"
      currency="USD"
      locale="en-US" 
      minFractionDigits={2}
    />
  );
};

export const tradeBodyTemplate = (rowData) => {
  const lineItem = lineItemsData.find(item => item.code === rowData.id);
  const icon = lineItem ? lineItem.icon : null;

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {icon && <img src={icon} alt={`${rowData.trade} icon`} style={{ width: '20px', marginRight: '8px' }} />}
      {rowData.trade}
    </div>
  );
};

export const actionBodyTemplate = (rowData, handleDelete) => {
    return (
      <React.Fragment>
        <Button 
          icon="pi pi-trash" 
          className="p-button-rounded p-button-secondary p-button-sm custom-delete-button"
          onClick={() => showDeleteConfirmation(() => handleDelete(rowData))} 
        />
      </React.Fragment>
    );
  };

// Export icons for use in other components
export const icons = {
  wrenchIcon,
  codeIcon,
  descriptionIcon,
  costIcon,
  bidBookIcon
};

export default {
  ColumnHeader,
  textEditor,
  numberEditor,
  tradeBodyTemplate,
  actionBodyTemplate,
  icons
};