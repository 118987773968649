import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { useUser } from "@clerk/clerk-react";
import useStore from "../store/store";
import CircularProgress from '@mui/material/CircularProgress';

const ProtectedRoute = ({ children }) => {
  const { isLoaded, isSignedIn, user } = useUser();
  const { checkSubscription } = useStore();
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [isChecking, setIsChecking] = useState(true);

  useEffect(() => {
    const verifySubscription = async () => {
      if (isSignedIn && user) {
        try {
          const hasActiveSubscription = await checkSubscription(user.primaryEmailAddress.emailAddress);
          setIsSubscribed(hasActiveSubscription);
        } catch (error) {
          console.error('Error checking subscription:', error);
          setIsSubscribed(false);
        } finally {
          setIsChecking(false);
        }
      } else {
        setIsChecking(false);
      }
    };

    if (isLoaded) {
      verifySubscription();
    }
  }, [isLoaded, isSignedIn, user, checkSubscription]);

  if (!isLoaded || isChecking) {
    return (
      <div className="flex flex-col items-center justify-center min-h-screen">
        <CircularProgress sx={{ color: '#38824D' }} size={60} thickness={5} />
      </div>
    );
  }

  if (!isSignedIn) {
    return <Navigate to="/sign-in" replace />;
  }

  if (!isSubscribed) {
    return <Navigate to="/subscription-required" replace />;
  }

  return children;
};

export default ProtectedRoute;