import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Grid from '@mui/material/Grid';

const formatDollarAmount = (value) => {
  return value === '' ? '' : `$${new Intl.NumberFormat('en-US', { maximumFractionDigits: 0 }).format(value)}`;
};

const unformatDollarAmount = (value) => {
  return value.replace(/[$,]/g, '');
};

const Calculator = () => {
  const [pmBidTime, setPmBidTime] = useState('');
  const [consightBidTime, setConsightBidTime] = useState('');
  const [timeSavedPerProject, setTimeSavedPerProject] = useState('');
  const [pmSalary, setPmSalary] = useState('');
  const [consightMonthlyCost, setConsightMonthlyCost] = useState('');
  const [moneySavedPerProject, setMoneySavedPerProject] = useState('');
  const [numProjects, setNumProjects] = useState('');
  const [annualSavings, setAnnualSavings] = useState('');
  const [cumulativeLeadTimeSaved, setCumulativeLeadTimeSaved] = useState('');

  const handlePmBidTimeChange = (event) => {
    setPmBidTime(event.target.value);
    if (pmBidTime && consightBidTime) {
      calculateTimeSavedPerProject(event.target.value, consightBidTime);
    }
  };

  const handleConsightBidTimeChange = (event) => {
    setConsightBidTime(event.target.value);
    if (pmBidTime && event.target.value) {
      calculateTimeSavedPerProject(pmBidTime, event.target.value);
    }
  };

  const calculateTimeSavedPerProject = (pmTime, consightTime) => {
    const timeSaved = pmTime - consightTime;
    setTimeSavedPerProject(timeSaved);
    if (pmSalary) {
      calculateMoneySavedPerProject(timeSaved, unformatDollarAmount(pmSalary));
    }
    if (numProjects) {
      calculateCumulativeLeadTimeSaved(timeSaved, numProjects);
    }
  };

  const calculateMoneySavedPerProject = (timeSaved, salary) => {
    const moneySaved = (timeSaved / 260.714) * salary;
    setMoneySavedPerProject(moneySaved);
    if (numProjects && consightMonthlyCost) {
      calculateAnnualSavings(moneySaved, numProjects, unformatDollarAmount(consightMonthlyCost));
    }
  };

  const handlePmSalaryChange = (event) => {
    setPmSalary(unformatDollarAmount(event.target.value));
    if (timeSavedPerProject) {
      calculateMoneySavedPerProject(timeSavedPerProject, unformatDollarAmount(event.target.value));
    }
  };

  const handleConsightMonthlyCostChange = (event) => {
    setConsightMonthlyCost(unformatDollarAmount(event.target.value));
    if (moneySavedPerProject && numProjects) {
      calculateAnnualSavings(moneySavedPerProject, numProjects, unformatDollarAmount(event.target.value));
    }
  };

  const handleNumProjectsChange = (event) => {
    setNumProjects(event.target.value);
    if (moneySavedPerProject && consightMonthlyCost) {
      calculateAnnualSavings(moneySavedPerProject, event.target.value, unformatDollarAmount(consightMonthlyCost));
    }
    if (timeSavedPerProject) {
      calculateCumulativeLeadTimeSaved(timeSavedPerProject, event.target.value);
    }
  };

  const calculateAnnualSavings = (moneySavedPerProject, numProjects, consightMonthlyCost) => {
    const annualMoneySaved = moneySavedPerProject * numProjects;
    const annualConsightCost = consightMonthlyCost * 12;
    const savings = annualMoneySaved - annualConsightCost;
    setAnnualSavings(savings);
  };

  const calculateCumulativeLeadTimeSaved = (timeSavedPerProject, numProjects) => {
    const cumulativeLeadTime = timeSavedPerProject * numProjects;
    setCumulativeLeadTimeSaved(cumulativeLeadTime);
  };

  return (
    <Box display="flex" justifyContent="center" mt={4}>
      <Grid container spacing={1} maxWidth="sm" sx={{ border: '1px solid #ccc', borderRadius: '15px', padding: '20px', backgroundColor: 'white' }}>
        <Grid item xs={12}>
          <Typography
            variant="h5"
            gutterBottom
            align="center"
            fontFamily="SF Pro Display"
            fontWeight="bold"
          >
            Savings Calculator
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <TextField
                color="success"
                label="Days for PM to level bids manually"
                value={pmBidTime}
                onChange={handlePmBidTimeChange}
                fullWidth
                margin="dense"
                type="number"
                InputProps={{
                  endAdornment: <InputAdornment position="end">work day(s)</InputAdornment>,
                }}
                size="small"
                fontFamily="SF Pro Display"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                color="success"
                label="Days for PM to level bids using Consight"
                value={consightBidTime}
                onChange={handleConsightBidTimeChange}
                fullWidth
                margin="dense"
                type="number"
                InputProps={{
                  endAdornment: <InputAdornment position="end">work day(s)</InputAdornment>,
                }}
                size="small"
                fontFamily="SF Pro Display"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                color="success"
                label="PM's salary"
                value={formatDollarAmount(pmSalary)}
                onChange={handlePmSalaryChange}
                fullWidth
                margin="dense"
                type="text"
                size="small"
                fontFamily="SF Pro Display"
                InputProps={{
                  endAdornment: <InputAdornment position="end">per year</InputAdornment>,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                color="success"
                label="Number of projects per year"
                value={numProjects}
                onChange={handleNumProjectsChange}
                fullWidth
                margin="dense"
                type="number"
                InputProps={{
                  endAdornment: <InputAdornment position="end">project(s)</InputAdornment>,
                }}
                size="small"
                fontFamily="SF Pro Display"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                color="success"
                label="Consight Subscription"
                value={formatDollarAmount(consightMonthlyCost)}
                onChange={handleConsightMonthlyCostChange}
                fullWidth
                margin="dense"
                type="text"
                InputProps={{
                  style: { backgroundColor: 'rgba(144, 238, 144, 0)' },
                  endAdornment: <InputAdornment position="end">per month</InputAdornment>,
                }}
                size="small"
                fontFamily="SF Pro Display"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                color="success"
                label="Time you save by using Consight"
                value={timeSavedPerProject}
                fullWidth
                margin="dense"
                type="number"
                InputProps={{
                  readOnly: true,
                  style: { backgroundColor: 'rgba(144, 238, 144, 0.2)', fontWeight: 'bold' },
                  endAdornment: <InputAdornment position="end">work days per project</InputAdornment>,

                }}
                size="small"
                fontFamily="SF Pro Display"
              />
            </Grid>
            <Grid item xs={12} mb={2}>
              <TextField
                color="success"
                label="Money you save by using Consight"
                value={formatDollarAmount(moneySavedPerProject)}
                fullWidth
                margin="dense"
                type="text"
                InputProps={{
                  readOnly: true,
                  style: { backgroundColor: 'rgba(144, 238, 144, 0.2)', fontWeight: 'bold' },
                  endAdornment: <InputAdornment position="end">per project</InputAdornment>,
                }}
                size="small"
                fontFamily="SF Pro Display"
              />
            </Grid>
            <Grid item xs={12} container justifyContent="center" sx={{ border: '2px solid #38824D', padding: 1, borderRadius: 2 }}>
              <Grid item xs={12}>
                <TextField
                  color="success"
                  label="Cumulative lead time saved"
                  value={cumulativeLeadTimeSaved}
                  fullWidth
                  margin="dense"
                  type="number"
                  InputProps={{
                    readOnly: true,
                    endAdornment: <InputAdornment position="end">work days per year</InputAdornment>,
                    style: { backgroundColor: 'rgba(144, 238, 144, 0.2)', fontWeight: 'bold' },
                  }}
                  size="small"
                  fontFamily="SF Pro Display"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  color="success"
                  label="Your total savings"
                  value={formatDollarAmount(annualSavings)}
                  fullWidth
                  margin="dense"
                  type="text"
                  InputProps={{
                    readOnly: true,
                    style: { backgroundColor: 'rgba(144, 238, 144, 0.2)', fontWeight: 'bold' },
                    endAdornment: <InputAdornment position="end">per year</InputAdornment>,
                  }}
                  size="small"
                  fontFamily="SF Pro Display"
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Calculator;